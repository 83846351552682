import axios from "axios";
import { toast } from "react-toastify";
import { TaskExtractModel } from "./useExtractMyTask";

export function compareTaskEndDate(a: any, b: any) {
  // Ensure dates are valid Date objects and in format yyyy-mm-dd
  const dateA = a.endDate;
  const dateB = b.endDate;
  if (dateB < dateA) {
    return -1;
  } else if (dateB > dateA) {
    return 1;
  }
  return 0;
}

const statusOrder = [
  "CREATED",
  "INPROGRESS",
  "COMPLETED",
  "CANCELLED",
  "CLOSED",
];

// Custom comparator to sort tasks based on the defined order
export const compareByStatus = (
  a: TaskExtractModel,
  b: TaskExtractModel
): number => {
  let statusAIndex = statusOrder.indexOf(a.status);
  let statusBIndex = statusOrder.indexOf(b.status);
  if (a.status === "CREATED") {
    statusAIndex += 1;
  }
  if (b.status === "CREATED") {
    statusBIndex += 1;
  }

  // If statusA comes before statusB, return -1, if after return 1, if same return 0
  return statusAIndex - statusBIndex;
};

export function compareTransactions(a: any, b: any) {
  // Ensure dates are valid Date objects and in format yyyy-mm-dd
  const dateA = a.endDate;
  const dateB = b.endDate;
  if (dateB > dateA) {
    return -1;
  } else if (dateB < dateA) {
    return 1;
  }
  return 0;
}

export const combinedSort = (
  a: TaskExtractModel,
  b: TaskExtractModel
): number => {
  const statusComparison = compareByStatus(a, b);

  // If status is the same, compare by endDate
  if (statusComparison === 0) {
    return compareTransactions(a, b);
  }

  // Otherwise, return the result of the status comparison
  return statusComparison;
};
export function compareTaskStartDate(a: any, b: any) {
  // Ensure dates are valid Date objects and in format yyyy-mm-dd
  const dateA = a.startDate;
  const dateB = b.startDate;
  if (dateB < dateA) {
    return -1;
  } else if (dateB > dateA) {
    return 1;
  }
  return 0;
}

export function compareHistory(a: any, b: any) {
  // Ensure dates are valid Date objects and in format yyyy-mm-dd
  const dateA = a.timestamp;
  const dateB = b.timestamp;
  if (dateB < dateA) {
    return -1;
  } else if (dateB > dateA) {
    return 1;
  }
  return 0;
}

export const handleS3Url = async (url: any, fileName: string) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.target = "_blank";
  anchor.download = fileName;
  anchor.click();
};
