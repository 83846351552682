import React, { useState, useRef, useEffect } from "react";
import StateBadge from "../dashboard/StateBadge";
import ReactDOM from "react-dom";

const options = [
  { id: "CREATED", label: "OPEN", color: "text-blue-600", disabled: false },
  {
    id: "INPROGRESS",
    label: "IN PROGRESS",
    color: "text-yellow-600",
    disabled: false,
  },
  {
    id: "COMPLETED",
    label: "COMPLETE",
    color: "text-green-600",
    disabled: false,
  },
  { id: "CANCELLED", label: "CANCEL", color: "text-red-600", disabled: false },
  { id: "CLOSED", label: "CLOSE", color: "text-white", disabled: false },
];

const isAssigneeOptions = [
  { id: "CREATED", label: "OPEN", color: "text-blue-600", disabled: false },
  {
    id: "INPROGRESS",
    label: "IN PROGRESS",
    color: "text-yellow-600",
    disabled: false,
  },
  {
    id: "COMPLETED",
    label: "COMPLETE",
    color: "text-green-600",
    disabled: false,
  },
];
const MAX_MENU_HEIGHT = 100;
const AVG_OPTION_HEIGHT = 40;

export function CustomSelect({
  data,
  isPreview = false,
  onChange,
  isAssignee = false,
}: {
  data: string;
  isPreview?: boolean;
  onChange: any;
  isAssignee?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(
    options.find((ele) => data === ele.id)
  );
  const contextRef = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const [dropUp, setDropUp] = useState(false);
  useEffect(() => {
    if (dropdownOpen) determineDropUp();
    document.addEventListener("mousedown", determineDropUp);

    return () => {
      document.removeEventListener("mousedown", determineDropUp);
    };
  }, [dropdownOpen]);
  const selectInst = useRef<HTMLDivElement>(null);
  const determineDropUp = () => {
    const options = [];
    const node = ReactDOM.findDOMNode(selectInst.current) as any;
    const node1 = ReactDOM.findDOMNode(contextRef.current) as any;
    if (!node) return;
    const windowHeight = window.innerHeight;
    const menuHeight = Math.min(
      MAX_MENU_HEIGHT,
      options.length * AVG_OPTION_HEIGHT
    );
    // Usage

    const dropMenuHeight = selectInst.current?.clientHeight || 0;
    const instOffsetWithMenu = node.getBoundingClientRect().bottom + menuHeight;

    setDropUp(windowHeight - instOffsetWithMenu > dropMenuHeight);
    //instOffsetWithMenu >= windowHeight
  };
  useEffect(() => {
    setSelectedOption(options.find((ele) => data === ele.id));
  }, [data]);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className={`flex flex-col`} ref={contextRef}>
      <div
        className="inline-flex text-xs text-center rounded-lg peer"
        onClick={() => {
          if (!isPreview) setDropDownOpen((state) => !state);
        }}
      >
        {selectedOption && (
          <StateBadge
            state={selectedOption.label}
            onClick={() => {}}
            isPreview={isPreview}
          />
        )}
      </div>

      {dropdownOpen && (
        <div
          id="DropDownList"
          className="origin-top-right z-50 absolute left-0 top-12 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
        >
          <div className="py-1">
            {(isAssignee ? isAssigneeOptions : options).map((option) => (
              <button
                key={option.id}
                onClick={(e: any) => {
                  if (!option.disabled) {
                    e.preventDefault();
                    // onChange(action);
                    setDropDownOpen((state) => !state);
                  }
                  handleOptionClick(option);
                }}
                className={`${
                  option.id === selectedOption.id ? "bg-gray-100" : ""
                } ${
                  option.color
                } group flex items-center w-full px-4 py-2 text-sm font-semibold hover:bg-slate-100`}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
