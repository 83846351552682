import React, { useEffect, useMemo, useState } from "react";
import {
  File,
  Folder,
  Download,
  Trash2,
  Image,
  FileText,
  Archive,
  Grid,
  List,
  ChevronDown,
  ChevronRight,
  Link,
  SquareArrowOutUpRight,
  Link2,
  Paperclip,
  Heading,
} from "lucide-react";
import ShowPdfModal from "../../modal/PdfViewModal";
import {
  useCreateProjectDocument,
  useDeleteProjectDocument,
  useGetPresignedURLforProjectDocument,
  useGetProjectDocuments,
} from "../../queries/document";
import { handleS3Url } from "../myTask/taskUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { HStack, VStack } from "../../shared/utils";
import DocumentEmptyScreen from "../myTask/DocumentEmptyScreen";
import SearchBar from "../../shared/SearchBar";
import { formatDisplayDate } from "../../utils/date";
import UploadLinkModal from "../../modal/UploadLink";
import { link } from "fs";
import { extractDateTimeFromISO, limitString } from "../../utils/string";
import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/outline";
import {
  ButtonSize,
  IconCTAButton,
  PrimaryCTAButton,
} from "../../shared/CTAButtonComponents";
import StateBadge from "../dashboard/StateBadge";
import IframePopupDialog from "./IFramePopup";
import {
  createProjectDocument,
  generatePresignedUrlForProject,
} from "../../api/document";
import { reach } from "yup";
import { queryClient } from "../../queries/client";
import CustomDownloadDialog from "./CustomDialogBox";
import { useAuthStore } from "../../store/useAuthStore";

const tabs = [
  { id: "project", label: "Project Attachments", icon: Folder },
  { id: "task", label: "Task Attachments", icon: FileText },
];

const tabs2 = [{ id: "project", label: "Project Attachments", icon: Folder }];

const fileTypes: any = {
  pdf: { icon: FileText, color: "text-red-500", bgColor: "bg-red-100" },
  doc: { icon: FileText, color: "text-blue-500", bgColor: "bg-blue-100" },
  xls: { icon: FileText, color: "text-green-500", bgColor: "bg-green-100" },
  xlsx: { icon: FileText, color: "text-green-500", bgColor: "bg-green-100" },
  xlm: { icon: FileText, color: "text-green-500", bgColor: "bg-green-100" },
  jpg: { icon: Image, color: "text-purple-500", bgColor: "bg-purple-100" },
  png: { icon: Image, color: "text-purple-500", bgColor: "bg-purple-100" },
  link: { icon: Link, color: "text-blue-500", bgColor: "bg-blue-100" },
  LINK: { icon: Link, color: "text-blue-500", bgColor: "bg-blue-100" },
  jpeg: { icon: Image, color: "text-purple-500", bgColor: "bg-purple-100" },
  zip: { icon: Archive, color: "text-yellow-500", bgColor: "bg-yellow-100" },
  hissa: { icon: Heading, color: "text-orange-600", bgColor: "bg-orange-50" },
};

export function FileItem({
  projectId,
  taskId,
  isProject,
  file,
  onDownload,
  onDelete,
  view,
  isUser = false,
  isSelected,
  onSelect,
}: {
  file: any;
  onDownload: any;
  onDelete: any;
  isProject?: boolean;
  view: any;
  projectId?: any;
  taskId?: any;
  isUser: boolean;
  isSelected?: boolean;
  onSelect?: (file: any) => void;
}) {
  let isHissa = false;
  if (file.type === "LINK")
    isHissa = (file.location ?? "").includes(".hissa.com");
  const fileExtension =
    file.type === "LINK"
      ? isHissa
        ? "hissa"
        : "link"
      : file.name.split(".").pop().toLowerCase();
  const FileIcon = fileTypes[fileExtension]?.icon || File;
  const iconColor = fileTypes[fileExtension]?.color || "text-gray-400";
  const iconBgColor = fileTypes[fileExtension]?.bgColor || "bg-gray-100";
  const [showPdf, setShowPdf] = useState(false);
  const [clicked, setClicked] = useState(false);
  return (
    <div
      className={`relative group ${
        view === "grid" ? "w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2" : "w-full"
      }`}
    >
      {showPdf && (
        <ShowPdfModal
          projectId={projectId}
          taskId={taskId}
          pdfName={file.name}
          isOpen={showPdf}
          onClose={() => setShowPdf(false)}
        />
      )}
      <div
        onClick={
          isHissa
            ? () => {
                setClicked(true);
              }
            : file.type === "LINK"
            ? () => {
                window.open(file.location);
              }
            : () => {}
        }
        className={`bg-white cursor-pointer rounded-lg shadow-sm overflow-hidden transition-all duration-200 
                      ${
                        view === "grid" ? "hover:shadow-md" : "hover:bg-gray-50"
                      } 
                      ${
                        view === "grid"
                          ? "flex flex-col items-center p-4"
                          : "flex items-center justify-between p-3"
                      }`}
      >
        {isProject && (
          <span className="absolute top-0 right-0 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            <Paperclip className="w-4 h-4 mr-1" />
            Project
          </span>
        )}

        <div
          className={`flex items-center ${
            view === "grid" ? "flex-col text-center" : "space-x-4"
          }`}
        >
          {file.type !== "LINK" ? (
            <input
              type="checkbox"
              checked={isSelected}
              disabled={file.type === "LINK"}
              onChange={() => onSelect && onSelect(file)}
              onClick={(e) => e.stopPropagation()}
              className={
                view === "grid" ? "mr-2 absolute left-4 top-4" : "mr-2"
              }
            />
          ) : (
            <div className="w-6"></div>
          )}
          <div
            className={`p-3 rounded-full ${iconBgColor} ${
              view === "grid" ? "mb-3" : ""
            }`}
          >
            <FileIcon className={`w-6 h-6 ${iconColor}`} />
          </div>
          <div className={view === "grid" ? "mt-2" : ""}>
            <span className="text-sm font-medium text-gray-700 block">
              {limitString(file.name, 40)}
            </span>
            {file.type === "LINK" ? (
              <p className="text-xs text-gray-500 gap-4">
                <span>
                  {formatDisplayDate(file.uploadedAt)} |{" "}
                  {extractDateTimeFromISO(file.uploadedAt).time}
                </span>
              </p>
            ) : (
              <p className="text-xs text-gray-500 gap-4">
                <span>
                  {" "}
                  {file.size / 1024 / 1024 > 1
                    ? `${(file.size / 1024 / 1024).toFixed(2)} MB`
                    : `${(file.size / 1024).toFixed(2)} KB`}
                </span>{" "}
                |{" "}
                <span>
                  {formatDisplayDate(file.uploadedAt)} |{" "}
                  {extractDateTimeFromISO(file.uploadedAt).time}
                </span>
              </p>
            )}
            <p className="text-xs text-gray-500">{file.userName}</p>
          </div>
        </div>
        <div
          className={`flex items-center space-x-2 ${
            view === "grid" ? "mt-4" : ""
          }`}
        >
          {file.type !== "LINK" ? (
            <button
              onClick={() =>
                taskId ? onDownload(file, taskId) : onDownload(file)
              }
              className="text-gray-400 hover:text-blue-600 p-1 rounded-full hover:bg-blue-100 transition-all duration-200"
            >
              <Download className="w-5 h-5" />
            </button>
          ) : (file.location ?? "").includes(".hissa.com") ? (
            <IframePopupDialog
              name={file.name}
              link={file.location}
              open={clicked}
            />
          ) : (
            <a
              href={file.location}
              target="_blank"
              className="text-gray-400 hover:text-blue-600 p-1 rounded-full hover:bg-blue-100 transition-all duration-200"
            >
              <SquareArrowOutUpRight className="w-5 h-5" />
            </a>
          )}
          {isUser && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(file);
              }}
              className="text-gray-400 hover:text-red-600 p-1 rounded-full hover:bg-red-100 transition-all duration-200"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const TaskAccordion = ({
  tasks,
  onDownload,
  onDelete,
  view,
  searchTerm,
  selectedFiles,
  onSelectFile,
}: {
  tasks: any;
  onDownload: any;
  onDelete: any;
  view: any;
  searchTerm: any;
  selectedFiles: any[];
  onSelectFile: (file: any) => void;
}) => {
  const [openTasks, setOpenTasks] = useState<any[]>([]);

  const toggleTask = (taskId: any) => {
    setOpenTasks((prev: any) =>
      prev.includes(taskId)
        ? prev.filter((id: any) => id !== taskId)
        : [...prev, taskId]
    );
  };

  return (
    <div className="space-y-4">
      {tasks.map((task: any) => (
        <div
          key={task.id}
          className="border border-gray-200 rounded-lg overflow-hidden"
        >
          <button
            className="w-full px-4 py-3 bg-gray-50 hover:bg-gray-100 flex items-center justify-between transition-colors duration-200"
            onClick={() => toggleTask(task.id)}
          >
            <VStack className="items-start">
              <span className="font-medium text-gray-700 tracking-widest">
                {task.name}
              </span>
              <span className="font-normal text-gray-700 text-sm">
                {task.documents.length > 0
                  ? `${task.documents.length} attachments`
                  : `0 attachment`}
              </span>
            </VStack>
            {task.id && openTasks.includes(task.id) ? (
              <ChevronDown className="w-5 h-5 text-gray-500" />
            ) : (
              <ChevronRight className="w-5 h-5 text-gray-500" />
            )}
          </button>
          {task.id && openTasks.includes(task.id) && (
            <div
              className={`bg-white p-4 ${
                view === "grid" ? "flex flex-wrap" : ""
              }`}
            >
              {task.documents.map((file: any) => (
                <FileItem
                  key={file.id}
                  isUser={true}
                  file={file}
                  taskId={task.id}
                  onDownload={onDownload}
                  onDelete={onDelete}
                  view={view}
                  isSelected={selectedFiles.some((f) => f.id === file.id)}
                  onSelect={onSelectFile}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export function AttachmentPage({
  project,
  isAdmin,
}: {
  project: any;
  isAdmin: boolean;
}) {
  const { isSubscribed } = useAuthStore();
  const [activeTab, setActiveTab] = useState("project");
  const [searchTerm, setSearchTerm] = useState("");
  const [view, setView] = useState("list");
  const [taskDocuments, setDocuments] = useState<any>([]);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  useEffect(() => {
    const documents: any[] = [];
    project.tasks.forEach((ele: any) => {
      if (ele.documents.length > 0) documents.push(ele);
    });
    setDocuments(documents);
  }, []);

  const { data: projectDocuments } = useGetProjectDocuments(project.id);
  const { mutate: generateS3UrlForProject } =
    useGetPresignedURLforProjectDocument();
  const { mutate: uploadProjectDocument } = useCreateProjectDocument();
  const { mutate: deleteDocument } = useDeleteProjectDocument();

  const handleAddFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          const presignedUrl = await generatePresignedUrlForProject({
            projectId: project.id,
            fileName: file.name,
            size: file.size,
            isProject: true,
            isUpload: true,
          });

          const response = await axios.put(presignedUrl.data, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          if (response.status === 200) {
            await createProjectDocument({
              projectId: project.id,
              type: "DOCUMENT",
              tags: project?.tags,
              size: file.size,
              name: file.name,
              location: file.name,
            });
            toast(`${file.name} uploaded successfully`, {
              type: "success",
              autoClose: 2000,
            });
          } else {
            throw new Error(`Failed to upload ${file.name}`);
          }
        } catch (error: any) {
          const errorMessage = error?.response?.data?.message ?? "";
          console.error(`Error uploading ${file.name}:`, error);
          toast(errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        }
      }
      queryClient.invalidateQueries("get-project-documents");
    }
    event.target.value = "";
  };

  const handleDownload = (fileName: any, taskId?: string) => {
    const taskFilePostData = taskId
      ? {
          taskId: taskId,
          projectId: project.id,
          fileName: fileName.name,
          isProject: false,
          isUpload: false,
        }
      : {
          projectId: project.id,
          fileName: fileName.name,
          isProject: true,
          isUpload: false,
        };

    generateS3UrlForProject(taskFilePostData, {
      onSuccess: async (data) => {
        const presignedUrl = data.data;
        handleS3Url(presignedUrl, fileName);
      },
      onError: (data) => {},
    });
  };

  const handleDelete = (documentId: string, projectId: string) => {
    deleteDocument(
      {
        documentId: documentId,
        projectId: projectId,
      },
      {
        onSuccess: (data) => {
          toast("Attachment deleted successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (data: any) => {
          toast(data.response.data.message, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  };

  const handleSelectFile = (file: any) => {
    setSelectedFiles((prev) => {
      const isSelected = prev.some((f) => f.id === file.id);
      if (isSelected) {
        return prev.filter((f) => f.id !== file.id);
      } else {
        return [...prev, file];
      }
    });
  };

  const [isDownloading, setIsDownloading] = useState(false);

  const [modalOpen, setModelOpen] = useState<{
    state: boolean;
    data: any;
  }>({
    state: false,
    data: undefined,
  });

  return (
    <div className="bg-white rounded-xl shadow-lg space-y-6">
      <div className="px-8 py-2 border-b border-gray-200">
        <HStack className="justify-between">
          {modalOpen?.data && (
            <UploadLinkModal
              taskIdData={modalOpen?.data?.taskId}
              projectId={modalOpen.data.id}
              isOpen={modalOpen.state}
              onClose={() => {
                setModelOpen({
                  state: false,
                  data: null,
                });
              }}
            />
          )}
          <VStack>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Attachments
            </h2>
            <p className="mt-1 sm:block hidden text-sm leading-6 text-gray-600">
              All project related attachments are shown below
            </p>
          </VStack>
          <HStack className="gap-4">
            <CustomDownloadDialog
              isOpen={isDownloading}
              selected={selectedFiles}
              onOpenChange={setIsDownloading}
              projectId={project.id}
              isProject={activeTab === "project"}
            />

            {isAdmin && (
              <PrimaryCTAButton
                className="h-8"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setModelOpen({
                    data: {
                      id: project.id,
                      taskId: "",
                    },
                    state: true,
                  });
                }}
              >
                <Link className="w-4 h-4 mr-1" />
                <p>Add Link</p>
              </PrimaryCTAButton>
            )}
            {isAdmin && (
              <PrimaryCTAButton className="h-8" buttonSize={ButtonSize.SMALL}>
                <label
                  htmlFor="file-upload"
                  className="flex flex-row items-center gap-2 cursor-pointer"
                >
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    multiple
                    onChange={handleAddFile}
                  />
                  <p>Upload</p>
                </label>
              </PrimaryCTAButton>
            )}
          </HStack>
        </HStack>
      </div>
      <div className="flex sm:flex-row flex-col  justify-between items-center mx-4 mr-8">
        <div className="flex space-x-1 bg-gray-100 rounded-lg p-1 ">
          {(isSubscribed ? tabs : tabs2).map((tab) => (
            <button
              key={tab.id}
              className={`flex-1 flex items-center justify-center space-x-2 px-4 py-2 rounded-md font-medium text-sm focus:outline-none transition-all duration-200 ${
                activeTab === tab.id
                  ? "bg-white text-blue-600 shadow-sm"
                  : "text-gray-500 hover:text-gray-700"
              }`}
              onClick={() => {
                setActiveTab(tab.id);
                setSelectedFiles([]);
              }}
            >
              <tab.icon className="w-4 h-4" />
              <span className="whitespace-nowrap">{tab.label}</span>
            </button>
          ))}
        </div>

        <HStack className="gap-4">
          {selectedFiles.length > 0 && (
            <button
              onClick={() => setIsDownloading(!isDownloading)}
              className="flex sm:block hidden items-center px-3 py-1 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100"
            >
              <HStack className="items-center">
                <Download className="w-4 h-4 mr-1" />
                <p>Download Selected ({selectedFiles.length})</p>
              </HStack>
            </button>
          )}
          <SearchBar
            id="search"
            name="search"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Files.."
          />

          {activeTab === "project" && (
            <button
              onClick={() => setView("grid")}
              className={`p-2 rounded-md ${
                view === "grid" ? "bg-gray-200" : "hover:bg-gray-100"
              }`}
            >
              <Grid className="w-5 h-5 text-gray-600" />
            </button>
          )}
          <button
            onClick={() => setView("list")}
            className={`p-2 rounded-md ${
              view === "list" ? "bg-gray-200" : "hover:bg-gray-100"
            }`}
          >
            <List className="w-5 h-5 text-gray-600" />
          </button>
          <IconCTAButton
            value={"Download all"}
            onClick={() => setIsDownloading(!isDownloading)}
            selected={isDownloading}
            iconName={"lucide:download"}
            iconClassName="block w-4 h-4"
            textClassName="sm:block hidden"
            className={`sm:px-2 px-1 -py-2 text-base font-medium items-center flex flex-row ${
              isDownloading ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
        {selectedFiles.length > 0 && (
          <button
            onClick={() => setIsDownloading(!isDownloading)}
            className="sm:hidden my-4 flex flex-1 w-full  items-center px-3 py-1 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100"
          >
            <HStack className="items-center mx-auto">
              <Download className="w-4 h-4 mr-1" />
              Download Selected ({selectedFiles.length})
            </HStack>
          </button>
        )}
      </div>

      <div className="bg-gray-50 rounded-lg overflow-hidden p-4">
        {activeTab === "project" ? (
          (projectDocuments ?? [])?.length > 0 ? (
            <div
              className={view === "grid" ? "flex flex-wrap -mx-2" : "space-y-2"}
            >
              {projectDocuments
                .filter((ele: any) =>
                  ele.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((file: any) => (
                  <FileItem
                    key={file.id}
                    file={file}
                    isUser={true}
                    onDownload={handleDownload}
                    onDelete={() => {
                      handleDelete(file.id, project.id);
                    }}
                    view={view}
                    isSelected={selectedFiles.some((f) => f.id === file.id)}
                    onSelect={handleSelectFile}
                  />
                ))}
            </div>
          ) : (
            <div className="text-center py-12 text-gray-500">
              <FileText className="w-16 h-16 text-gray-400 mx-auto mb-4" />
              <p className="text-xl font-semibold mb-2">
                No project files found
              </p>
              <p>
                {searchTerm
                  ? "Try adjusting your search term."
                  : "Upload some files to get started!"}
              </p>
            </div>
          )
        ) : (
          <TaskAccordion
            tasks={taskDocuments}
            onDownload={handleDownload}
            onDelete={handleDelete}
            view={view}
            searchTerm={searchTerm}
            selectedFiles={selectedFiles}
            onSelectFile={handleSelectFile}
          />
        )}
      </div>
    </div>
  );
}
