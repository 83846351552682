import React, { useState } from "react";
import { motion } from "framer-motion";
import { List, Calendar } from "lucide-react";

const ViewSwitch = ({
  view,
  setView,
}: {
  view: string;
  setView: (view: string) => void;
}) => {
  return (
    <div className="flex justify-center items-center">
      <div className="bg-white rounded-xl p-1 shadow-md">
        <div className="flex relative">
          {/* <motion.div
            className="absolute top-0 left-0 w-1/2 h-full bg-gray-200 rounded-lg"
            animate={{ x: view === "list" ? "0%" : "100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          /> */}
          <button
            className={`relative z-10 px-4 mr-4 gap-2 py-2 text-sm flex flex-row items-center rounded-lg focus:outline-none ${
              view === "list" ? "text-gray-800  font-medium transition-opacity bg-gray-200" : "text-gray-500"
            }`}
            onClick={() => setView("list")}
          >
            <List size={18} />
            <p>List</p>
          </button>
          <button
            className={`relative flex flex-row gap-2 items-center text-sm  z-10 px-4 py-2 rounded-lg focus:outline-none ${
              view === "calendar" ? "text-gray-800   font-medium transition-opacity bg-gray-200" : "text-gray-500"
            }`}
            onClick={() => setView("calendar")}
          >
            <Calendar size={18} />
            <p>Calender</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewSwitch;
