import { useEffect, useRef, useState } from "react";
import {
  List,
  FileText,
  Activity,
  Users,
  ChevronRight,
  Building2,
  CalendarCheck,
} from "lucide-react";
import { HStack } from "../../shared/utils";
import { IssueStateDots } from "../myTask/IssueState";
import { MembersTab } from "./ProjectMember";
import { AttachmentPage } from "./AttachmentsTab";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetAllTask,
  useGetProjectDetail,
  useGetProjectExport,
  useGetTask,
} from "../../queries/admin";
import { formatDisplayDate } from "../../utils/date";
import OptionMenus from "./OptionMenus";
import DeleteProjectModal from "../../modal/DeleteProject";
import CreateProjectModal from "../../modal/ProjectCreateModal";
import ComingSoonActivities from "../myTask/ComingSoonActivities";
import { useAuthStore } from "../../store/useAuthStore";
import { useGetUserProjectRoles } from "../../queries/userRole";
import { TaskExtractModel } from "../myTask/useExtractMyTask";
import { UserUpdateSelectComponent } from "../myTask/UserUpdate";
import AGGridSimpleTable from "../aggrid/AGGridSimpleTable";
import { downloadExcel } from "../../shared/DownloadFile";
import { toast } from "react-toastify";
import { queryClient } from "../../queries/client";
import Setting from "./Setting";
import IframePopupDialog from "./IFramePopup";
import { TableId } from "./TableIdConstants";
import SideBarPanel from "../myTask/SideBarPanel";

const tabs = [
  { id: "list", name: "Task", icon: List },
  { id: "attachments", name: "Attachments", icon: FileText },
  { id: "members", name: "Members", icon: Users },
  { id: "settings", name: "Settings", icon: Activity },
];

const tabs2 = [
  { id: "list", name: "Task", icon: List },
  { id: "attachments", name: "Attachments", icon: FileText },
];

export default function ProjectPageUI() {
  const { id, taskId } = useParams();
  const { data: project, refetch: refetchAllTask } = useGetAllTask(id ?? "");

  useEffect(() => {
    if (id) refetchAllTask();
  }, [id]);
  const [taskList, setTaskList] = useState([]);
  useEffect(() => {
    if (project) setTaskList(project.tasks ?? []);
  }, [project]);

  const [isAdmin, setIsAdmin] = useState(false);
  const { id: authId, isSubscribed } = useAuthStore();
  const { data: userProjectRoles } = useGetUserProjectRoles(id);
  useEffect(() => {
    const user = userProjectRoles?.find(
      (data) => data.role === "ADMIN" && data.userId === authId
    );
    if (user) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userProjectRoles]);

  const { data: projectDetails, refetch } = useGetProjectDetail(id ?? "");
  const { data: taskDetails, refetch: refetchTask } = useGetTask(
    taskId ?? ""
  );

  useEffect(() => {
    if (taskId) refetchTask();
  }, []);
  useEffect(() => {
    if (taskDetails) setRouteTask(true);
  }, [taskDetails]);

  const [activeTab, setActiveTab] = useState("list");
  const issueCounts = {
    open: taskList.filter((ele: any) => ele?.status === "CREATED").length,
    inprogress: taskList.filter((ele: any) => ele?.status === "INPROGRESS")
      .length,
    completed: taskList.filter((ele: any) => ele?.status === "COMPLETED")
      .length,
    closed: taskList.filter((ele: any) => ele?.status === "CLOSED").length,
    cancelled: taskList.filter((ele: any) => ele?.status === "CANCELLED")
      .length,
  };
  const handleFilterChange = (newFilters: any) => {
    setSelectedFilters(newFilters);
  };
  const navigate = useNavigate();
  const [isDeleteProject, setDeleteProject] = useState(false);
  const [isUpdateProjectDetails, setUpdateProjectDetails] = useState(false);
  const [routeTask, setRouteTask] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState<TaskExtractModel[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const statuses = [
    "CREATED",
    "INPROGRESS",
    "COMPLETED",
    "CLOSED",
    "CANCELLED",
  ];
  const [selectedFilters, setSelectedFilters] = useState<string[]>(statuses);
  useEffect(() => {
    const membersMap = new Map<string | number, any>();
    let filtered = taskList.filter((task: TaskExtractModel) => {
      (userProjectRoles ?? []).forEach((role) => {
        if (role?.id) {
          membersMap.set(role.userId, { id: role.userId, name: role.name });
        }
      });

      return selectedFilters.includes(task?.status);
    });
    setFilteredTasks(filtered ?? []);
    setUsers(Array.from(membersMap.values()));
  }, [taskList, selectedFilters]);
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { refetch: getReport } = useGetProjectExport(id ?? "");
  const { accessToken } = useAuthStore();
  const handleAddFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!event?.target?.files?.[0]) return;
    const fileData = event.target.files[0];
    const allowedFileExtensions = [".xlsm", ".xlsx"];
    if (
      fileData &&
      allowedFileExtensions.some((extension) =>
        fileData.name.endsWith(extension)
      )
    ) {
      const formData = new FormData();
      formData.append("file", fileData);

      try {
        const baseUrl =
          process.env.REACT_APP_API ||
          "https://dev-taskmanager-api.hissa.com/v1/";
        const response = await fetch(`${baseUrl}/project/import`, {
          method: "POST",
          headers: {
            AccessToken: `${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        toast("Task Imported Successfully", {
          type: "success",
          autoClose: 2000,
        });
        queryClient.invalidateQueries("getAllProjects");
        queryClient.invalidateQueries("overview");
      } catch (error) {}
    }
  };

  return (
    <>
      {projectDetails && (
        <div className="">
          <DeleteProjectModal
            projectIdData={projectDetails.id}
            projectName={projectDetails.name}
            isOpen={isDeleteProject}
            onClose={() => {
              setDeleteProject(false);
            }}
            onCreateProject={() => {
              setDeleteProject(false);
            }}
          />
          {isUpdateProjectDetails && (
            <CreateProjectModal
              project={projectDetails}
              isOpen={isUpdateProjectDetails}
              onClose={() => {
                setUpdateProjectDetails(false);
              }}
              onCreateProject={() => {
                setUpdateProjectDetails(false);
                refetch();
              }}
            />
          )}

          <div className="mb-6 flex items-center text-sm text-gray-500 breadcrumbs">
            <ul className="flex space-x-2">
              <li>
                <a
                  onClick={() => {
                    navigate("/tasks");
                  }}
                  className="hover:text-indigo-600 cursor-pointer transition-colors duration-200"
                >
                  Projects
                </a>
              </li>
              <li>
                <ChevronRight className="w-4 h-4" />
              </li>
              <li className="text-gray-900 font-medium">
                {projectDetails.name}
              </li>
            </ul>
          </div>

          <div className="mb-12 bg-white rounded-2xl shadow-xl sm:p-8 p-6 transform transition-all duration-300 hover:shadow-2xl">
            <div className="flex justify-between items-start mb-6">
              <div className="w-full">
                <HStack className="justify-between w-full">
                  <h1 className="text-2xl font-bold tracking-wide text-gray-900 mb-2">
                    {projectDetails.name}
                  </h1>
                  <div className="flex items-center space-x-4 pb-4">
                    {/* <button
                      onClick={() => setIsStarred(!isStarred)}
                      className={`p-2 rounded-full transition-colors duration-200 ${
                        isStarred
                          ? "bg-yellow-100 text-yellow-500"
                          : "bg-gray-100 text-gray-400 hover:bg-gray-200"
                      }`}
                    >
                      <Star className="w-5 h-5" />
                    </button> */}
                    {/* <button className="text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100 transition-all duration-200">
                      <MoreVertical className="w-5 h-5" />
                    </button> */}
                    {projectDetails.companyName && (
                      <div className="bg-primary/10 text-primary font-semibold gap-2 flex flex-row items-center text-xs px-3 py-1 rounded-full">
                        <Building2 className="w-4 h-4" />
                        <p>{projectDetails.companyName}</p>
                      </div>
                    )}
                    <input
                      ref={fileRef}
                      onChange={handleAddFile}
                      multiple={false}
                      type="file"
                      accept=".xlsx, .xlsm"
                      hidden
                    />
                    {isAdmin && (
                      <OptionMenus
                        options={[
                          {
                            name: "Export to Excel",
                            onClick: () => {
                              getReport().then((data) => {
                                downloadExcel(
                                  data.data,
                                  projectDetails.name ??
                                    "" + projectDetails.companyName ??
                                    ""
                                );
                              });
                            },
                          },
                          {
                            name: "Import from Excel",
                            onClick: () => {
                              fileRef.current.click();
                            },
                          },
                          {
                            name: "Update Project",
                            onClick: () => {
                              setUpdateProjectDetails(true);
                            },
                          },
                          {
                            name: "Delete Project",
                            onClick: () => {
                              setDeleteProject(true);
                            },
                          },
                        ]}
                      />
                    )}
                    {/* <button className="p-2 rounded-full bg-gray-100 text-gray-400 hover:bg-gray-200 transition-colors duration-200">
                  <Bell className="w-5 h-5" />
                </button>
                <button className="p-2 rounded-full bg-gray-100 text-gray-400 hover:bg-gray-200 transition-colors duration-200">
                  <MoreVertical className="w-5 h-5" />
                </button> */}
                  </div>
                </HStack>

                <p className="text-sm text-gray-600 mb-4">
                  {projectDetails.description}
                </p>
                <div className="flex items-center space-x-4">
                  {projectDetails.endDate && (
                    <div className="bg-indigo-100 text-indigo-800 font-semibold gap-2 flex flex-row items-center text-xs px-3 py-1 rounded-full">
                      <CalendarCheck className="w-4 h-4" />
                      <p> Due: {formatDisplayDate(projectDetails.endDate)}</p>
                    </div>
                  )}
                </div>
                <HStack className="justify-between">
                  <div className="flex flex-col pt-4 text-xs text-gray-500 font-semibold">
                    <p>Admin's :</p>
                    <HStack className="">
                      <UserUpdateSelectComponent
                        users={(userProjectRoles ?? [])
                          .filter((user) => user.role === "ADMIN" && user.name)
                          .map((user) => ({
                            id: user.id,
                            name: user.name,
                          }))}
                        onChange={() => {}}
                      />
                    </HStack>
                  </div>
                  {isSubscribed && (
                    <div className="flex flex-col pt-4 text-xs text-gray-500 font-semibold">
                      <p>Member's:</p>
                      <HStack className="">
                        <UserUpdateSelectComponent
                          users={(userProjectRoles ?? [])
                            .filter(
                              (user) => user.role === "MEMBER" && user.name
                            )
                            .map((user) => ({
                              id: user.id,
                              name: user.name,
                            }))}
                          onChange={() => {}}
                        />
                      </HStack>
                    </div>
                  )}
                </HStack>
              </div>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
              <div
                className="bg-indigo-600 h-2 rounded-full"
                style={{
                  width: `${
                    (taskList.filter((task: any) => task.status === "CLOSED")
                      .length /
                      taskList.length) *
                    100
                  }%`,
                }}
              ></div>
            </div>
            <HStack className="justify-between sm:flex-row flex-col-reverse">
              <IssueStateDots counts={issueCounts} />
              <div className="text-right text-sm text-gray-500">
                Task Closed:{" "}
                <span className="font-semibold text-indigo-600">
                  {
                    taskList.filter((task: any) => task.status === "CLOSED")
                      .length
                  }
                  /{taskList.length}
                </span>
              </div>
            </HStack>
          </div>

          <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
            <nav className="flex border-b border-gray-200" aria-label="Tabs">
              {(isSubscribed ? tabs : tabs2).map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`
                ${
                  activeTab === tab.id
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }
                flex-1 py-4 px-6 text-center font-medium text-sm
                flex items-center justify-center transition-all duration-200 ease-in-out
                focus:outline-none
                border-b-2
              `}
                >
                  <tab.icon
                    className={`w-5 h-5 mr-2 ${
                      activeTab === tab.id ? "text-indigo-500" : "text-gray-400"
                    }`}
                  />
                  <span className="sm:block hidden">{tab.name}</span>
                </button>
              ))}
            </nav>
            {taskDetails && routeTask && (
              <SideBarPanel
                tags={[]}
                realTasks={taskDetails}
                // task={isUpdateProjectDetails.task}
                open={routeTask}
                onClose={() => {
                  setRouteTask(false);
                }}
                isAdmin={true}
              />
            )}
            {project ? (
              activeTab !== "members" ? (
                activeTab !== "settings" ? (
                  activeTab !== "attachments" ? (
                    <AGGridSimpleTable
                      tasks={filteredTasks}
                      project={project}
                      tableId={TableId.projectTaskTable + project.id}
                    />
                  ) : (
                    <AttachmentPage isAdmin={isAdmin} project={project} />
                  )
                ) : (
                  <Setting isAdmin={isAdmin} project={project} />
                )
              ) : (
                <MembersTab project={projectDetails} />
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
}
