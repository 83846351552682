import React from "react";

const CommentEmptyScreen = () => {
  return (
    <div>
      <div className="w-60 mx-auto pt-16 justify-center items-center">
        <img src="/comment.png" />
      </div>
      <p className="text-gray-900 font-medium w-72 text-center mx-auto">
        Task update? Question? Roadblock?
      </p>
      <p className="text-gray-600 text-sm w-72 text-center mx-auto">
        Comment here to keep everyone in the loop.
      </p>
    </div>
  );
};

export default CommentEmptyScreen;
