import { useMutation, useQuery } from "react-query";
import { handleEventForTracking } from "../analytics";
import { createComment, getCommentDetails, updateComment } from "../api/comment";
import { queryClient } from "./client";

export function useCreateComment() {
  return useMutation({
    mutationKey: "create-comment",
    mutationFn: createComment,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllComment");
      queryClient.invalidateQueries("getAllTaskHistory");
      handleEventForTracking({
        eventName: "create-comment",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "create-comment",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateComment() {
  
  return useMutation({
    mutationKey: "update-comment",
    mutationFn: updateComment,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllComment");
      queryClient.invalidateQueries("getAllTaskHistory");
      handleEventForTracking({
        eventName: "update-comment",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "update-comment",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetAllComment(taskId:any) {
  return useQuery({
    queryKey: ["getAllComment",taskId],
    queryFn: getCommentDetails,
    select: (data) => data.data
  });
}