import {
  ForgotPasswordDetailModel,
  SignInDetailsModel,
  SignResponseModel,
} from "../types/CaptableLoginModel";
import api from "../queries/api";
import captableApi from "../queries/cpatableApi";

export async function forgotPassword(
  forgotPasswordDetail: ForgotPasswordDetailModel
): Promise<{ message: string; data: any }> {
  return captableApi
    .post(`auth/forgotPassword`, forgotPasswordDetail)
    .then((res: any) => res.data);
}
//REACT_APP_CAPTABLE_API
export async function captableSignIn(
  signInDetails: SignInDetailsModel
): Promise<{ message: string; data: SignResponseModel }> {
  return api.post(`auth/login`, signInDetails).then((res: any) => res.data);
}
