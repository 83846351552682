import React from "react";
import {
  Calendar,
  User,
  Clock,
  Flag,
  Users,
  MessageSquare,
  Paperclip,
  Activity,
} from "lucide-react";

const Shimmer = ({
  width = "w-full",
  height = "h-4",
  className,
}: {
  width?: string;
  height?: string;
  className?: string;
}) => (
  <div
    className={`animate-pulse bg-gray-200 rounded ${width} ${height} ${className}`}
  ></div>
);

const TaskDetailShimmer = () => {
  return (
    <div className="w-full h-full overflow-y-auto bg-gradient-to-br from-blue-50 to-indigo-50 flex flex-col shadow-xl rounded-lg overflow-hidden">
      <div className="bg-white p-6 shadow-md">
        <div className="flex justify-between items-center mb-4">
          <Shimmer width="w-3/4" height="h-8" />
          <div className="flex space-x-2">
            <Shimmer width="w-8" height="h-8" />
            <Shimmer width="w-8" height="h-8" />
          </div>
        </div>
        <Shimmer className="mb-6" height="h-16" />
        <div className="grid grid-cols-2 gap-4 text-sm mb-6">
          {[...Array(6)].map((_, index) => (
            <div key={index} className="flex items-center">
              {/* {[Calendar, User, Clock, Flag, Users][index % 5]({
                className: "w-5 h-5 mr-2 text-blue-500",
              })} */}
              <Shimmer width="w-2/3" />
            </div>
          ))}
        </div>
        <div className="mb-4 flex flex-wrap items-center gap-2">
          <Shimmer width="w-16" />
          {[...Array(3)].map((_, index) => (
            <Shimmer key={index} width="w-20" height="h-6" />
          ))}
        </div>
      </div>
      <div className="flex-grow flex flex-col">
        <div className="flex bg-gray-100">
          {[MessageSquare, Paperclip, Activity].map((Icon, index) => (
            <div key={index} className="flex-1 p-3 text-center">
              <Icon className="w-5 h-5 mx-auto mb-1" />
              <Shimmer width="w-16" height="h-4" className="mx-auto" />
            </div>
          ))}
        </div>
        <div className="bg-white p-4 overflow-y-auto flex-grow">
          <div className="space-y-4">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-md">
                <div className="flex items-center mb-2">
                  <Shimmer
                    width="w-10"
                    height="h-10"
                    className="rounded-full mr-3"
                  />
                  <div>
                    <Shimmer width="w-32" className="mb-1" />
                    <Shimmer width="w-24" height="h-3" />
                  </div>
                </div>
                <Shimmer className="mt-2" height="h-12" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailShimmer;
