import React from "react";

const TaskEmptyScreen = ({ text }: { text: string }) => {
  return (
    <div className="w-full">
      <div className="w-60 mx-auto pt-16justify-center items-center">
        <img src="/task.png" />
      </div>
      <p className="text-gray-900 font-medium w-72 text-center mx-auto">
        Priority Tasks
      </p>
      <p className="text-gray-600 text-sm w-72 pb-8 text-center mx-auto">
        {text}, will appear here
      </p>
    </div>
  );
};

export default TaskEmptyScreen;
