export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function extractDateTimeFromISO(isoString: string) {
  const date = new Date(isoString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  let hours: any = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = String(hours).padStart(2, "0");

  return {
    date: `${year}-${month}-${day}`,
    time: `${hours}:${minutes} ${ampm}`,
  };
}

export function limitString(str: string, limit: number) {
  if (str.length > limit) {
    return str.substring(0, limit).concat(" . . .");
  } else {
    return str.substring(0, limit);
  }
}

export function capitalizeFirstWord(str: string) {
  if (!str) return str;

  const words = str.split(" ");
  if (words.length === 0) return str;

  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

  for (let i = 1; i < words.length; i++) {
    words[i] = words[i].toLowerCase();
  }

  return words.join(" ");
}
