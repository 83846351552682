import React from "react";

const BlurOverlay = ({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}) => {
  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur  z-30"
      onClick={onClose}
    />
  );
};

export default BlurOverlay;
