import { useMemo } from "react";
import { TaskModel } from "../../types/TaskModel";
import { RecurringConfig } from "./SideBarAddTaskPanel";

export interface TaskExtractModel {
  creatorId: string;
  groupName: string;
  isActive?: boolean;
  parentTaskId: string;
  isRecurring: boolean;
  isGroupTask: boolean;
  recurringConfig: RecurringConfig[];
  taskType: "" | "INDIVIDUAL";
  orgHierarchy: string[];
  description: string;
  projectDescription: string;
  endDate?: string;
  id: string;
  name: string;
  priority: string;
  isRead: string;
  projectId: string;
  projectName: string;
  companyName: string;
  projectSpecificStatusId?: string | null;
  startDate: string;
  status: string;
  tags: string[];
  userTaskRoles: ExtractUserRole[];
}

export interface ExtractUserRole {
  email: string;
  id: string;
  name: string;
  role: string;
  taskId: string;
  userId: string;
}

const useExtractMyTasks = (
  projects: any,
  userId: string
): { assignerTask: TaskExtractModel[]; assigneeTask: TaskExtractModel[] } => {
  let assignerTask: any[] = [];
  let assigneeTask: any[] = [];
  useMemo(() => {
    if (projects)
      return projects.forEach((project: any) => {
        const assigner = project.tasks
          .filter((task: any) =>
            task.userTaskRoles.find(
              (user: any) => user.userId === userId && user.role === "ASSIGNER"
            )
          )
          .map((task: any) => ({
            ...task,
            projectName: project.name,
            projectId: project.id,
          }));
        const assignee = project.tasks
          .filter((task: any) =>
            task.userTaskRoles.find(
              (user: any) => user.userId === userId && user.role === "ASSIGNEE"
            )
          )
          .map((task: any) => ({
            ...task,
            projectName: project.name,
            projectId: project.id,
          }));

        if (assigner) assignerTask = [...assignerTask, ...assigner];
        if (assignee) assigneeTask = [...assigneeTask, ...assignee];
      });
    else return [];
  }, [projects]);

  return { assignerTask, assigneeTask };
};

export default useExtractMyTasks;
