import { Search } from "lucide-react";

type InputProps = {
  error?: boolean;
  label?: string;
  helperText?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export default function SearchBar(props: InputProps) {
  const { className: _className, placeholder, ...rest } = props;

  return (
    <div>
      <div className="relative flex items-center">
        <div className="absolute inset-y-0 left-0 flex py-1.5 pl-2">
          <Search className="text-gray-400 w-4 h-4 mt-2 z-10" />
        </div>
        <input
          {...rest}
          id={props.label}
          className={`block w-full h-11 pl-8 rounded-xl py-1.5 pr-14 text-gray-900 shadow-box border border-secondary/10 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${_className}`}
          placeholder={placeholder}
          aria-label={props.label}
          aria-describedby={props.helperText}
          onWheel={(e) => e.currentTarget.blur()}
          autoComplete="off"
          //
        />
      </div>
    </div>
  );
}
