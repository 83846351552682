import { QueryFunctionContext } from "react-query";
import api from "../queries/api";
import { CommentModel } from "../types/TaskModel";

export async function getCommentDetails(
    context: QueryFunctionContext
  ): Promise<any> {
    const taskId = context.queryKey[1];
    return api.get(`project/task/comment/all`, { params: { taskId } }).then((res) => res.data);
  }
  
  export async function createComment(
    commentDetails: CommentModel
  ): Promise<any> {
    return api.post(`project/task/comment/`, commentDetails).then((res) => res.data);
  }
  
  export async function updateComment(
    projectDetails: CommentModel
  ): Promise<any> {
    return api.put(`project/task/comment/`, projectDetails).then((res) => res.data);
  }
  
  export async function deleteComment(commentId: any,taskId:any): Promise<any> {
    return api.delete(`project/task/comment/?commentId=${commentId}&taskId=${taskId}`).then((res) => res.data);
  }
  