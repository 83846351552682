import { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import LazyPdfDocument from "../pages/myTask/LazyPdfDocument";
import { useGetPresignedURLforTaskDocument } from "../queries/document";

const ShowPdfModal = ({
  projectId,
  taskId,
  pdfName,
  isOpen,
  onClose,
}: {
  projectId: string;
  taskId: string;
  pdfName: string;
  isOpen: any;
  onClose: any;
}) => {
  const { mutate: generateS3UrlForTask } = useGetPresignedURLforTaskDocument();
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    generateS3UrlForTask(
      {
        taskId: taskId,
        projectId: projectId,
        fileName: pdfName,
        isProject: false,
        isUpload: false,
      },
      {
        onSuccess: async (data: any) => {
          const presignedUrl = data.data;
          setPdfUrl(presignedUrl);
        },
      }
    );
  }, [pdfName]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {pdfUrl ? (
                  <LazyPdfDocument url={pdfUrl ?? ""} />
                ) : (
                  <p>Loading</p>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ShowPdfModal;
