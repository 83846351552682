import { Navigate, useRoutes } from "react-router-dom";
import Page404 from "./pages/Page404";
import MainLayout from "./layout/MainLayout";
import { useAuthStore } from "./store/useAuthStore";
import SettingsPage from "./pages/setting/SettingsPage";
import HomePage from "./pages/dashboard/HomePage";
import MyTaskPage from "./pages/myTask/MyTaskPage";
import ProjectPage from "./pages/project/ProjectPage";
import CaptableLoginPage from "./pages/auth/Login";
import ResetPasswordPage from "./pages/auth/ResetpasswordPage";
import ForgotPasswordPage from "./pages/auth/ForgotPassword";
import ApprovalScreen from "./pages/approval/ApprovalScreen";
import CalenderPage from "./pages/calender/CalenderPage";
import FileDownloadScreen from "./pages/fileDownload/FileDownloadScreen";
export default function Router() {
  const { isAuthenticated } = useAuthStore();

  return useRoutes([
    {
      path: "/login",
      element: <CaptableLoginPage />,
    },

    {
      path: "/forgotpassword",
      element: <ForgotPasswordPage />,
    },
    {
      path: "/resetpassword/",
      element: <ResetPasswordPage />,
    },
    {
      path: "/",
      element: <Navigate to="/tasks" replace />,
    },
    {
      path: "/approveTask",
      element: <ApprovalScreen />,
    },
    {
      path: `/fileDownload`,
      element: <FileDownloadScreen />,
    },
    {
      path: "/",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "home",
          element: <HomePage />,
        },
        {
          path: "tasks",
          element: <MyTaskPage />,
        },
        {
          path: "calender",
          element: <CalenderPage />,
        },
        {
          path: "project/:id",
          element: <ProjectPage />,
        },
        {
          path: "project/:id/:taskId",
          element: <ProjectPage />,
        },
        {
          path: "settings",
          element: <SettingsPage />,
        },
      ],
    },
    {
      path: "/404",
      element: <Page404 />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
