import { useEffect } from "react";

const FileDownloadScreen = () => {
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    let fileUrl = params.get("fileUrl") || "";

    params.forEach((value, key) => {
      if (key !== "fileUrl") {
        fileUrl += `&${key}=${value}`;
      }
    });

    const downloadAndClose = async () => {
      try {
        await downloadS3File(fileUrl);
      } catch (error) {
      
      }
      window.close();
    };

    downloadAndClose();
  }, []);

  return <></>;
};

async function downloadS3File(file: string, fileName = "") {
  const anchor = document.createElement("a");
  anchor.href = file;
  anchor.target = "_blank";
  anchor.download = fileName;
  anchor.click();
}

export default FileDownloadScreen;
