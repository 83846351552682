import { useState } from "react";
import { useAuthStore } from "../../store/useAuthStore";
import { useGetOverview } from "../../queries/admin";
import useExtractTasks from "./ExtractTaskHook";
import MyTaskEmptyScreen from "./MyTaskEmptyScreen";
import AGGridSimpleTable from "../aggrid/AGGridSimpleTable";
import { TableId } from "../project/TableIdConstants";

const MyTaskPage = () => {
  const statuses = [
    "CREATED",
    "INPROGRESS",
    "COMPLETED",
    "CLOSED",
    "CANCELLED",
  ];

  const { data: userData } = useGetOverview();
  const allTask = useExtractTasks(userData);

  return (
    <div className="-mx-2">
      <AGGridSimpleTable tasks={allTask} tableId={TableId.myTaskTable} />
    </div>
  );
};

export default MyTaskPage;
