import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CustomSelect } from "./UserStateChange";
import {
  MessageSquare,
  Paperclip,
  Activity,
  Calendar,
  User,
  Clock,
  Send,
  Plus,
  Flag,
  Tag,
  Users,
  Bell,
  Check,
  X,
  Edit,
  Trash,
  ArrowLeft,
  XIcon,
} from "lucide-react";
import {
  useCreateComment,
  useGetAllComment,
  useUpdateComment,
} from "../../queries/comment";
import { useAuthStore } from "../../store/useAuthStore";
import _ from "lodash";
import { extractDateTimeFromISO } from "../../utils/string";
import { queryClient } from "../../queries/client";
import { HStack } from "../../shared/utils";
import OptionMenus from "../project/OptionMenus";
import { deleteComment } from "../../api/comment";
import {
  useGetAllTaskHistory,
  useGetTask,
  useUpdateTask,
  useUpdateTaskRead,
} from "../../queries/admin";
import Tooltip from "../../shared/Tooltip";
import {
  useCreateUserTaskRole,
  useDeleteUserTaskRole,
  useGetUserProjectRoles,
  useGetUserTaskRoles,
} from "../../queries/userRole";
import SearchAutoCompleteDropDown, {
  SwitchDropDownModel,
} from "./SearchInputBox";
import { UserProjectRoles } from "../../api/userRole";
import { CommentModel } from "../../types/TaskModel";
import { formatDisplayDate } from "../../utils/date";
import SearchStringAutoCompleteDropDown from "./SearchStringInputBox";
import {
  useCreateTaskDocument,
  useDeleteTaskDocument,
  useGetPresignedURLforTaskDocument,
  useGetProjectDocuments,
  useGetTaskDocuments,
} from "../../queries/document";
import axios from "axios";
import {
  createTaskDocument,
  generatePresignedUrlForTask,
  TaskDocument,
} from "../../api/document";
import { compareHistory, handleS3Url } from "./taskUtils";
import CommentEmptyScreen from "./CommentEmptyScreen";
import DocumentEmptyScreen from "./DocumentEmptyScreen";
import { FileItem } from "../project/AttachmentsTab";
import DeleteTaskModal from "../../modal/DeleteTask";
import MailReminderModal from "../../modal/MailReminder";
import ActivitiesSection from "../aggrid/Activity";
import UploadLinkModal from "../../modal/UploadLink";
import UpdateSubTaskModal from "../../modal/UpdateSubTask";
import { RecurringConfig } from "./SideBarAddTaskPanel";
import { Input } from "../../shared/InputField";
import {
  EditableFields,
  TabButton,
  TaskModel,
  TaskSidebarProps,
} from "./SideBarPanel";
import { motion } from "framer-motion";
import TaskDetailShimmer from "./Shimmer";

export interface TaskSidePanelProps {
  realTasks?: any;
  isAdmin: boolean;
  onClose: any;
  tags?: string[];
}

const TaskDetailPanel: React.FC<TaskSidePanelProps> = ({
  realTasks,
  tags,
  onClose,
}) => {
  const { data: realTask } = useGetTask(realTasks.id ?? "");
  const { data: history } = useGetAllTaskHistory(realTasks.id ?? "");
  const nextRecurringDate =
    realTask?.recurrenceData?.nextInstanceStartDate ?? "";
  const { id } = useAuthStore();
  const [isAdmin, setIsAdmin] = useState(false);

  const { mutate: updateTask } = useUpdateTask();
  const { mutate: addComment } = useCreateComment();
  const { mutate: updateTheComment } = useUpdateComment();
  const { mutate: updateTaskRead } = useUpdateTaskRead();
  useEffect(() => {
    updateTaskRead({ taskId: realTask?.id ?? "" });
  }, [realTask]);
  const { data: comments } = useGetAllComment(realTask?.id ?? "");
  const authStore = useAuthStore();

  const [intervalMonth, setIntervalMonth] = useState(3);
  const [recurringEndDate, setRecurringEndDate] = useState(30);
  const [recurringLastDate, setRecurringLastDate] = useState("");
  const [recurringStartDate, setRecurringStartDate] = useState(20);

  const [activeTab, setActiveTab] = useState<string>(
    isAdmin ? "activity" : "comments"
  );
  const [newComment, setNewComment] = useState<string>("");
  const [updateComment, setUpdateComment] = useState<string>("");
  const [activityFilter, setActivityFilter] = useState<string>("all");
  const [editMode, setEditMode] = useState<EditableFields>({
    name: false,
    description: false,
    recurring: false,
    groupName: false,
    status: false,
    startDate: false,
    endDate: false,
    creator: false,
    assignedTo: false,
    priority: false,
    tags: false,
  });
  const [editedTask, setEditedTask] = useState<TaskModel>({ ...realTask });
  useEffect(() => {
    if (realTask) {
      setEditedTask(realTask);

      if (
        realTask.isRecurring &&
        realTask?.recurringConfig &&
        realTask?.recurringConfig?.length > 0
      ) {
        setIntervalMonth(realTask.recurringConfig[0].recurrenceInterval);
        setRecurringEndDate(realTask.recurringConfig[0].dueDate);
        setRecurringLastDate(realTask.recurringConfig[0].recurrenceEndDate);
        setRecurringStartDate(realTask.recurringConfig[0].startDateOffset);
      }
    }
  }, [realTask]);
  const [newTag, setNewTag] = useState<string>("");
  const [newAssignee, setNewAssignee] = useState<string>("");

  const handleEdit = (field: keyof EditableFields) => {
    if (isAdmin || field === "assignedTo") {
      setEditMode({ ...editMode, [field]: true });
    }
  };
  const [isEdit, setIsEdit] = useState(false);
  const [updateSubTasks, setUpdateSubTasks] = useState(false);

  const handleSave = (field: keyof EditableFields, value?: string) => {
    if (realTask) {
      if (field === "creator") {
        createManager({
          id: "",
          name: "",
          type: managerEmail,
        });
      } else if (field === "name") {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                name: editedTask.name,
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            name: editedTask.name,
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "description") {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                description: editedTask.description,
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            description: editedTask.description,
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "groupName") {
        updateTask({
          projectId: realTasks.projectId ?? "",
          taskId: realTask.id ?? "",
          groupName: editedTask.groupName,
          updateSubTasks: true,
        });
      } else if (field === "startDate" && editedTask.startDate) {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                startDate: editedTask.startDate
                  ? new Date(editedTask.startDate).toISOString()
                  : "",
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            startDate: new Date(editedTask.startDate).toISOString(),
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "endDate" && editedTask.endDate) {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                endDate: editedTask.endDate
                  ? new Date(editedTask.endDate).toISOString()
                  : "",
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            endDate: new Date(editedTask.endDate).toISOString(),
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "status") {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                status: value,
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            status: value,
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "priority") {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",
                priority: value,
                updateSubTasks: update,
              });
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            priority: value,
            updateSubTasks: updateSubTasks,
          });
        }
      } else if (field === "tags") {
        if (realTask.groupName && !realTask.parentTaskId) {
          setModelOpen({
            state: true,
            type: "Update SubTask",
            data: realTask,
            onComplete: (update: boolean) => {
              updateTask({
                projectId: realTasks.projectId ?? "",
                taskId: realTask.id ?? "",

                tags: value
                  ? [...(realTask?.tags ?? []), value]
                  : [...(realTask?.tags ?? []), newTag],
                updateSubTasks: update,
              });
              setNewTag("");
            },
          });
        } else {
          updateTask({
            projectId: realTasks.projectId ?? "",
            taskId: realTask.id ?? "",
            updateSubTasks: updateSubTasks,
            tags: value
              ? [...(realTask?.tags ?? []), value]
              : [...(realTask?.tags ?? []), newTag],
          });
          setNewTag("");
        }
      }
      setEditMode({ ...editMode, [field]: false });
      // Here you would typically update the task on the server
      Object.assign(realTask, editedTask);
    }
  };

  const handleRecurringSave = () => {
    if (realTask) {
      if (realTask.groupName && !realTask.parentTaskId) {
        setModelOpen({
          state: true,
          type: "Update SubTask",
          data: realTask,
          onComplete: (update: boolean) => {
            updateTask({
              projectId: realTasks.projectId ?? "",
              taskId: realTask.id ?? "",
              recurringConfig: [
                {
                  recurrenceInterval: intervalMonth,
                  startDateOffset: recurringStartDate,
                  dueDate: recurringEndDate,
                  recurrenceEndDate: recurringLastDate
                    ? new Date(recurringLastDate).toISOString()
                    : "",
                  createImmediately: false,
                },
              ],
              updateSubTasks: update,
            });
            setNewTag("");
          },
        });
      } else {
        updateTask({
          projectId: realTasks.projectId ?? "",
          taskId: realTask.id ?? "",
          updateSubTasks: updateSubTasks,
          recurringConfig: [
            {
              recurrenceInterval: intervalMonth,
              startDateOffset: recurringStartDate,
              dueDate: recurringEndDate,
              recurrenceEndDate: recurringLastDate
                ? new Date(recurringLastDate).toISOString()
                : "",
              createImmediately: false,
            },
          ],
        });
        setNewTag("");
      }
      setEditMode({ ...editMode, recurring: false });
      // Here you would typically update the task on the server
      Object.assign(realTask, editedTask);
    }
  };

  const handleCancel = (field: keyof EditableFields) => {
    setEditMode({ ...editMode, [field]: false });
    setEditedTask({ ...realTask });
  };

  const handleInputChange = (field: keyof TaskModel, value: any) => {
    setEditedTask({ ...editedTask, [field]: value });
  };

  const handleAddComment = () => {
    if (newComment.trim()) {
      updateComment
        ? updateTheComment({
            commentId: updateComment,
            userId: authStore.id ?? "",
            content: newComment,
            taskId: realTasks.id,
          })
        : addComment({
            userId: authStore.id ?? "",
            content: newComment,
            taskId: realTasks.id,
          });

      setNewComment("");
      setUpdateComment("");
    }
  };

  const handleAddFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          const presignedUrl = await generatePresignedUrlForTask({
            taskId: realTasks.id,
            projectId: realTasks.projectId,
            fileName: file.name,
            size: file.size,
            isProject: false,
            isUpload: true,
          });

          const response = await axios.put(presignedUrl.data, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          if (response.status === 200) {
            await createTaskDocument({
              projectId: realTasks.projectId,
              taskId: realTasks.id,
              type: "DOCUMENT", //LINK DOCUMENT
              tags: realTask?.tags,
              size: file.size,
              name: file.name,
              location: file.name, // send file name as location, in case of link send url
            });

            toast(`${file.name} uploaded successfully`, {
              type: "success",
              autoClose: 2000,
            });
          } else {
            throw new Error(`Failed to upload ${file.name}`);
          }
        } catch (error) {
          toast(`Error uploading ${file.name}`, {
            type: "error",
            autoClose: 2000,
          });
        }
      }
      queryClient.invalidateQueries("get-task-documents");
    }
  };

  const handleSendReminder = () => {
    setModelOpen({
      type: "Reminder",
      data: realTask,
      state: true,
    });
  };
  const handleTaskDelete = () => {
    setModelOpen({
      type: "Delete",
      data: realTask,
      state: true,
    });
  };

  const handleAddTag = () => {
    if (newTag && !(editedTask?.tags ?? []).includes(newTag)) {
      setEditedTask({
        ...editedTask,
        tags: [...(editedTask?.tags ?? []), newTag],
      });
      setNewTag("");
    }
  };

  const handleRemoveTag = (tag: string) => {
    setEditedTask({
      ...editedTask,
      tags: (editedTask?.tags ?? []).filter((t) => t !== tag),
    });
  };

  const priorityColor = {
    NORMAL: "bg-gray-100 text-gray-800",
    LOW: "bg-green-100 text-green-800",
    MEDIUM: "bg-yellow-100 text-yellow-800",
    HIGH: "bg-red-100 text-red-800",
    CRITICAL: "bg-red-100 text-red-800",
  };

  const { data: userProjectRoles } = useGetUserProjectRoles(
    realTasks.projectId
  );
  const { data: userTaskRoles } = useGetUserTaskRoles(realTasks.id);
  const [manager, setManager] = useState<UserProjectRoles[]>([]);
  const [assignee, setAssignee] = useState<UserProjectRoles[]>([]);
  const { mutate: assignTask } = useCreateUserTaskRole();
  const { mutate: deleteUserFromTask } = useDeleteUserTaskRole();
  useEffect(() => {
    if (userTaskRoles && userTaskRoles.length > 0) {
      const assigner = userTaskRoles?.find((ele) => ele.role === "ASSIGNER");
      const assignee = userTaskRoles?.find((ele) => ele.role === "ASSIGNEE");

      const assignerList =
        userTaskRoles?.filter((ele) => ele.role === "ASSIGNER") ?? [];
      const assigneeList =
        userTaskRoles?.filter((ele) => ele.role === "ASSIGNEE") ?? [];
      setAssignee(assigneeList);
      setManager(assignerList);
      if (assigner) {
        setSelectedTransaction({
          id: assigner?.id ?? "",
          type: assigner?.email ?? "",
          name: assigner?.name ?? "",
        });
      }
      // if (assignee) {
      //   setSelectedAssignee({
      //     id: assigner?.id ?? "",
      //     type: assigner?.email ?? "",
      //     name: assigner?.name ?? "",
      //   });
      // }
    }
  }, [userTaskRoles]);
  const [modalOpen, setModelOpen] = useState<{
    state: boolean;
    type: "Delete" | "Reminder" | "Link" | "Update SubTask";
    data: any;
    onComplete?: any;
  }>({
    type: "Delete",
    state: false,
    data: undefined,
    onComplete: () => {},
  });

  const [selectedTransaction, setSelectedTransaction] =
    useState<SwitchDropDownModel>({ name: "", id: "", type: "" });
  const [selectedAssignee, setSelectedAssignee] = useState<SwitchDropDownModel>(
    { name: "", id: "", type: "" }
  );

  function createManager(value?: SwitchDropDownModel) {
    if (value?.type) {
      assignTask(
        {
          projectId: realTasks.projectId,
          taskId: realTasks.id,
          email: value?.type ?? "",
          userId: value.id ?? "",
          role: "ASSIGNER",
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries("getUserTaskRoles");
            setEditMode({ ...editMode, ["creator"]: false });
          },
        }
      );
    }
  }

  function createAssignee(value?: SwitchDropDownModel) {
    if (value?.type) {
      assignTask(
        {
          projectId: realTasks.projectId,
          taskId: realTasks.id,
          email: value?.type ?? "",
          role: "ASSIGNEE",
          userId: value.id,
          sendNotification: sendNotification,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries("getUserTaskRoles");
            setEditMode({ ...editMode, ["creator"]: false });
          },
        }
      );
    }
  }

  const [managerEmail, setManagerEmail] = useState("");
  const [showAssignee, setShowAssignee] = useState(false);

  const { data: taskDocuments } = useGetTaskDocuments(realTasks.id);
  const { data: projectDocuments } = useGetProjectDocuments(
    realTasks.projectId
  );
  const { mutate: generateS3UrlForTask } = useGetPresignedURLforTaskDocument();
  const { mutate: uploadTaskDocument } = useCreateTaskDocument();

  const { mutate: deleteDocument } = useDeleteTaskDocument();

  const handleDownload = (fileName: any) => {
    generateS3UrlForTask(
      {
        taskId: realTasks.id,
        projectId: realTasks.projectId,
        fileName: fileName.name,
        isProject: false,
        isUpload: false,
      },
      {
        onSuccess: async (data) => {
          const presignedUrl = data.data;
          handleS3Url(presignedUrl, fileName);
        },
        onError: (data) => {},
      }
    );
  };

  const [sendNotification, setSendNotification] = useState(true);

  const handleDelete = (documentId: string, taskId: string) => {
    deleteDocument({
      documentId: documentId,
      taskId: taskId,
    });
  };
  const view = () => {};
  useEffect(() => {
    const user = userTaskRoles?.find(
      (data) => data.role === "ASSIGNER" && data.userId === id
    );
    if (user) {
      setIsAdmin(true);
      setActiveTab("activity");
    } else {
      setIsAdmin(false);
      setActiveTab("comments");
    }
  }, [userTaskRoles]);

  const [showProjectFile, setShowProjectFile] = useState(true);
  const [isAdminViewer, setIsAdminViewer] = useState(false);

  useEffect(() => {
    const user = userProjectRoles?.find(
      (data) => data.role === "VIEWER" && data.userId === id
    );
    if (user) {
      setIsAdminViewer(true);
    } else {
      setIsAdminViewer(false);
    }
  }, [userProjectRoles]);
  return (
    <>
      {!realTask ? (
        <TaskDetailShimmer />
      ) : (
        <>
          {modalOpen?.data && modalOpen?.type === "Delete" && (
            <DeleteTaskModal
              taskIdData={modalOpen?.data?.id}
              projectId={modalOpen.data.projectId}
              taskNameData={modalOpen?.data.name}
              isOpen={modalOpen.state}
              onClose={() => {
                setModelOpen({
                  state: false,
                  type: "Delete",
                  data: null,
                });

                onClose();
              }}
            />
          )}
          {modalOpen?.data && modalOpen?.type === "Update SubTask" && (
            <UpdateSubTaskModal
              taskIdData={modalOpen?.data?.id}
              taskData={modalOpen?.data}
              isOpen={modalOpen.state}
              onClose={() => {
                setModelOpen({
                  state: false,
                  type: "Delete",
                  data: null,
                });
                onClose();
              }}
              onComplete={modalOpen.onComplete}
            />
          )}
          {modalOpen?.data && modalOpen?.type === "Reminder" && (
            <MailReminderModal
              taskIdData={modalOpen?.data?.id}
              projectId={modalOpen.data.projectId}
              taskNameData={modalOpen?.data.name}
              isOpen={modalOpen.state}
              onClose={() => {
                setModelOpen({
                  state: false,
                  type: "Reminder",
                  data: null,
                });
              }}
            />
          )}

          {modalOpen?.data && modalOpen?.type === "Link" && (
            <UploadLinkModal
              taskIdData={modalOpen?.data?.taskId}
              projectId={modalOpen.data.id}
              isOpen={modalOpen.state}
              onClose={() => {
                setModelOpen({
                  state: false,
                  type: "Link",
                  data: null,
                });
              }}
            />
          )}

          <div className="w-full h-full overflow-y-auto bg-gradient-to-br from-blue-50 to-indigo-50 flex flex-col shadow-xl rounded-lg overflow-hidden">
            {/* Task Details - Now with more spacious design */}
            <div className="bg-white p-6 shadow-md">
              <HStack className="flex flex-row justify-between">
                <HStack className="justify-start items-start gap-4">
                  <XIcon className="mt-1 cursor-pointer" onClick={onClose} />
                  {editMode.name ? (
                    <div className="mb-4 flex flex-row">
                      <input
                        type="text"
                        value={editedTask.name}
                        onChange={(e) =>
                          handleInputChange("name", e.target.value)
                        }
                        className="text-2xl font-bold w-full border px-2 rounded-lg border-gray-300 focus:border-blue-500 focus:outline-none"
                      />
                      <div className="flex justify-end mt-2 ml-2">
                        <Check
                          className="w-6 h-6 text-green-500 cursor-pointer mr-2"
                          onClick={() => handleSave("name")}
                        />
                        <X
                          className="w-6 h-6 text-red-500 cursor-pointer"
                          onClick={() => handleCancel("name")}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-start">
                      <h2
                        onClick={() => (isAdmin ? handleEdit("name") : {})}
                        className="text-2xl hover:bg-gray-100 rounded-md font-bold mb-4 text-gray-800 flex items-center"
                      >
                        {editedTask.name}
                      </h2>
                    </div>
                  )}{" "}
                </HStack>
                {isAdmin && (
                  <HStack className="gap-4">
                    <Tooltip
                      text={"Delete Task"}
                      _className="whitespace-nowrap cursor-pointer"
                      position="top-8"
                    >
                      <div
                        onClick={handleTaskDelete}
                        className=" bg-blue-50 cursor-pointer text-blue-600 hover:text-white px-2 py-2 rounded-full hover:bg-red-600 transition-colors duration-300 flex items-center justify-center text-sm font-medium"
                      >
                        <Trash className="w-4 h-4" />
                      </div>
                    </Tooltip>
                    <Tooltip
                      text={"Send Reminder"}
                      _className="whitespace-nowrap cursor-pointer -ml-16"
                      position="top-8"
                    >
                      <div
                        onClick={handleSendReminder}
                        className=" bg-blue-50 text-blue-600 cursor-pointer hover:text-white px-2 py-2 rounded-full hover:bg-blue-600 transition-colors duration-300 flex items-center justify-center text-sm font-medium"
                      >
                        <Bell className="w-4 h-4" />
                      </div>
                    </Tooltip>
                  </HStack>
                )}
              </HStack>
              {editMode.description ? (
                <div className="mb-6">
                  <LabelText text={"Description"} />
                  <textarea
                    value={editedTask.description}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    className="w-full text-sm text-gray-600 border rounded-md p-2 focus:border-blue-500 focus:outline-none"
                    rows={4}
                  />
                  <div className="flex justify-end mt-2">
                    <Check
                      className="w-5 h-5 text-green-500 cursor-pointer mr-2"
                      onClick={() => handleSave("description")}
                    />
                    <X
                      className="w-5 h-5 text-red-500 cursor-pointer"
                      onClick={() => handleCancel("description")}
                    />
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => (isAdmin ? handleEdit("description") : {})}
                  className="flex flex-col items-start mb-6"
                >
                  <LabelText text={"Description"} />
                  <p className="text-sm text-gray-600 hover:bg-gray-100 leading-relaxed flex-grow">
                    {editedTask.description}
                  </p>
                </div>
              )}
              {realTask?.groupName && !realTask?.parentTaskId && (
                <>
                  {editMode.groupName ? (
                    <div className="mb-6">
                      <LabelText text={"Group Name"} />
                      <input
                        type="text"
                        value={editedTask.groupName}
                        onChange={(e) =>
                          handleInputChange("groupName", e.target.value)
                        }
                        className="w-full text-sm text-gray-600 border rounded-md p-2 focus:border-blue-500 focus:outline-none"
                      />

                      <div className="flex justify-end mt-2">
                        <Check
                          className="w-5 h-5 text-green-500 cursor-pointer mr-2"
                          onClick={() => handleSave("groupName")}
                        />
                        <X
                          className="w-5 h-5 text-red-500 cursor-pointer"
                          onClick={() => handleCancel("groupName")}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => (isAdmin ? handleEdit("groupName") : {})}
                      className="flex flex-col items-start mb-6"
                    >
                      <LabelText text={"Group Name"} />
                      <p className="text-sm text-gray-600 hover:bg-gray-100 leading-relaxed flex-grow">
                        {editedTask.groupName}
                      </p>
                    </div>
                  )}
                </>
              )}
              {/* <h2 className="text-2xl font-bold mb-4 text-gray-800">
                        {realTask?.name ?? ""}
                      </h2>
                      <p className="text-sm text-gray-600 mb-6 leading-relaxed">
                        {realTask?.description ?? ""}
                      </p> */}
              <div className="grid grid-cols-2 gap-4 text-sm mb-6">
                <div className="flex flex-col items-start">
                  <LabelText text={"Status"} />

                  <div className="flex items-center text-gray-700">
                    {/* <Clock className="w-5 h-5 mr-2 text-blue-500" /> */}
                    {/* {editMode.status ? ( */}
                    <div className="flex items-center">
                      {realTask?.status && (
                        <CustomSelect
                          isAssignee={!isAdmin}
                          data={realTask?.status ?? ""}
                          isPreview={isAdminViewer}
                          onChange={(data: any) => {
                            handleInputChange("status", data.id);
                            handleSave("status", data.id);
                            handleCancel("status");
                          }}
                        />
                      )}
                    </div>
                    {/* ) : (
                              <>
                                <span
                                  onClick={() =>
                                    isAdmin ? handleEdit("status") : {}
                                  }
                                >
                                  {realTask?.status && (
                                    <CustomSelect
                                      isAssignee={!isAdmin}
                                      data={realTask?.status ?? ""}
                                      onChange={(data: any) => {}}
                                    />
                                  )}
                                </span>
                              </>
                            )} */}
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <LabelText text={"Managed By"} />
                  <div className="flex items-center text-gray-700">
                    <User className="w-5 h-5 mr-2 text-blue-500" />
                    {editMode.creator ? (
                      <div className="flex items-center">
                        {/* <input
                                  type="text"
                                  value={editedTask.creator}
                                  onChange={(e) =>
                                    handleInputChange("creator", e.target.value)
                                  }
                                  className="text-sm border rounded px-2 py-1 mr-2"
                                /> */}
                        {selectedTransaction ? (
                          <div className=" w-40 rounded-md h-8">
                            <SearchAutoCompleteDropDown
                              placeholder="Add manager"
                              width="w-40"
                              onChange={(e) => {
                                setManagerEmail(e ?? "");
                              }}
                              hint="Enter valid email to add new user"
                              data={
                                (userProjectRoles ?? []).map(
                                  (user: UserProjectRoles) => ({
                                    name: user.name,
                                    type: user.email,
                                    id: user.id,
                                  })
                                ) as SwitchDropDownModel[]
                              }
                              getSelectedValue={createManager}
                              selectedOption={
                                selectedTransaction as SwitchDropDownModel
                              }
                              field={"name" as keyof SwitchDropDownModel}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <Check
                          className="w-5 h-5 text-green-500 cursor-pointer"
                          onClick={() => {
                            handleSave("creator");
                          }}
                        />
                        <X
                          className="w-5 h-5 text-red-500 cursor-pointer"
                          onClick={() => handleCancel("creator")}
                        />
                      </div>
                    ) : (
                      <>
                        <span
                          onClick={() => (isAdmin ? handleEdit("creator") : {})}
                          className="text-gray-800 hover:bg-gray-100"
                        >
                          {selectedTransaction.name}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <LabelText text={"Start Date"} />
                  <div className="flex items-center text-gray-700">
                    <Calendar className="w-5 h-5 mr-2 text-blue-500" />
                    {editMode.startDate ? (
                      <div className="flex items-center">
                        <input
                          type="date"
                          value={editedTask.startDate}
                          onChange={(e) => {
                            handleInputChange("startDate", e.target.value);
                          }}
                          className="text-sm border rounded px-2 py-1 mr-2"
                        />
                        <Check
                          className="w-5 h-5 text-green-500 cursor-pointer"
                          onClick={() => handleSave("startDate")}
                        />
                        <X
                          className="w-5 h-5 text-red-500 cursor-pointer"
                          onClick={() => handleCancel("startDate")}
                        />
                      </div>
                    ) : (
                      <>
                        <span
                          onClick={() =>
                            isAdmin ? handleEdit("startDate") : {}
                          }
                          className="text-gray-800 hover:bg-gray-100"
                        >
                          {formatDisplayDate(realTask?.startDate)}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <LabelText text={"Due Date"} />
                  <div className="flex items-center text-gray-700">
                    <Calendar className="w-5 h-5 mr-2 text-blue-500" />
                    {editMode.endDate ? (
                      <div className="flex items-center">
                        <input
                          type="date"
                          value={editedTask.endDate}
                          onChange={(e) =>
                            handleInputChange("endDate", e.target.value)
                          }
                          className="text-sm border rounded px-2 py-1 mr-2"
                        />
                        <Check
                          className="w-5 h-5 text-green-500 cursor-pointer"
                          onClick={() => handleSave("endDate")}
                        />
                        <X
                          className="w-5 h-5 text-red-500 cursor-pointer"
                          onClick={() => handleCancel("endDate")}
                        />
                      </div>
                    ) : (
                      <>
                        <span
                          onClick={() => (isAdmin ? handleEdit("endDate") : {})}
                          className="text-gray-800 hover:bg-gray-100"
                        >
                          {realTask?.endDate
                            ? formatDisplayDate(realTask?.endDate)
                            : "Not Specified"}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start ">
                  <LabelText text={"Priority"} />
                  <div className="flex items-center text-gray-700">
                    <Flag className="w-5 h-5 mr-2 text-blue-500" />
                    {editMode.priority ? (
                      <div className="flex items-center">
                        <select
                          value={editedTask.priority}
                          onChange={(e) => {
                            handleInputChange(
                              "priority",
                              e.target.value as
                                | "NORMAL"
                                | "LOW"
                                | "MEDIUM"
                                | "HIGH"
                                | "CRITICAL"
                            );
                            handleSave("priority", e.target.value);
                          }}
                          className="text-sm border rounded px-2 py-1 mr-2"
                        >
                          <option value="NORMAL">NORMAL</option>
                          <option value="LOW">LOW</option>
                          <option value="MEDIUM">MEDIUM</option>
                          <option value="HIGH">HIGH</option>
                          <option value="CRITICAL">CRITICAL</option>
                        </select>
                      </div>
                    ) : (
                      <>
                        <span
                          onClick={() =>
                            isAdmin ? handleEdit("priority") : {}
                          }
                          className={`px-3 py-1 rounded-full text-sm hover:bg-gray-100 font-medium ${
                            priorityColor[
                              realTask?.priority as
                                | "LOW"
                                | "NORMAL"
                                | "MEDIUM"
                                | "HIGH"
                                | "CRITICAL"
                            ]
                          }`}
                        >
                          {realTask?.priority ?? "LOW"}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start ">
                  <LabelText text={"Assigned to"} />
                  <div
                    onClick={() => {
                      handleEdit("assignedTo");
                    }}
                    className="flex hover:bg-gray-100 items-center text-gray-700"
                  >
                    <Users className="w-5 h-5 mr-2 text-blue-500" />
                    {assignee && assignee?.length > 0 ? (
                      <span className="text-gray-800">
                        {assignee[0]?.name}
                        {assignee?.length > 1 && (
                          <span className="text-blue-500">{` & ${
                            (assignee ?? []).length - 1
                          } More`}</span>
                        )}
                      </span>
                    ) : (
                      <span className="text-gray-800">Not Assigned</span>
                    )}
                  </div>
                </div>
              </div>

              {editMode.assignedTo && <LabelText text={"Assignee's :"} />}
              {editMode.assignedTo && (
                <div className="mb-4">
                  <div className="flex flex-wrap gap-2 mb-2">
                    {(assignee ?? []).map((roles, index) => (
                      <span
                        key={index}
                        className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium flex items-center"
                      >
                        {roles.name}
                        {editMode.assignedTo && isAdmin && (
                          <X
                            className="w-4 h-4 ml-2 cursor-pointer"
                            onClick={() => {
                              deleteUserFromTask({
                                projectId: realTasks.projectId,
                                email: roles.email,
                                taskId: realTasks.id,
                              });
                            }}
                          />
                        )}
                      </span>
                    ))}
                  </div>
                  {isAdmin && (
                    <div className="h-10">
                      <SearchAutoCompleteDropDown
                        placeholder="Add assignee"
                        onChange={(e) => {
                          setNewAssignee(e ?? "");
                        }}
                        data={
                          (userProjectRoles ?? [])
                            .map((user: UserProjectRoles) => ({
                              name: user.name,
                              type: user.email,
                              id: user.userId,
                            }))
                            .filter((ele) => {
                              return !assignee.find(
                                (ele2) => ele2.id === ele.id
                              );
                            }) as SwitchDropDownModel[]
                        }
                        getSelectedValue={createAssignee}
                        selectedOption={selectedAssignee as SwitchDropDownModel}
                        field={"name" as keyof SwitchDropDownModel}
                      />
                    </div>
                  )}

                  {isAdmin && editMode.assignedTo && (
                    <div className="flex justify-between mt-2">
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="notification"
                            aria-describedby="notification"
                            name="notification"
                            type="checkbox"
                            checked={sendNotification}
                            onClick={() => {
                              setSendNotification(!sendNotification);
                            }}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-900"
                          >
                            Send Notification
                          </label>
                        </div>
                      </div>
                      <div
                        onClick={() => handleCancel("assignedTo")}
                        className="text-gray-800 cursor-pointer border border-gray-700 ml-4 bg-white py-1 px-2 text-sm rounded-md"
                      >
                        Cancel
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="mb-4 flex flex-wrap items-center gap-2">
                <LabelText text={"Tags:"} />
                {(realTask?.tags ?? []).map((tag: any, index: any) => (
                  <span
                    key={index}
                    className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium flex items-center"
                  >
                    {tag}
                    {editMode.tags && (
                      <X
                        className="w-4 h-4 ml-2 cursor-pointer"
                        onClick={() => {
                          setNewTag("");
                          if (realTask?.groupName && !realTask?.parentTaskId) {
                            setModelOpen({
                              state: true,
                              type: "Update SubTask",
                              data: realTask,
                              onComplete: (update: boolean) => {
                                updateTask({
                                  projectId: realTasks.projectId ?? "",
                                  taskId: realTask?.id ?? "",
                                  tags: realTask?.tags?.filter(
                                    (ele: any) => ele !== tag
                                  ),
                                  updateSubTasks: update,
                                });
                              },
                            });
                          } else {
                            updateTask({
                              projectId: realTasks.projectId ?? "",
                              taskId: realTask?.id ?? "",
                              updateSubTasks: updateSubTasks,
                              tags: realTask?.tags?.filter(
                                (ele: any) => ele !== tag
                              ),
                            });
                          }
                        }}
                      />
                    )}
                  </span>
                ))}

                {isAdmin && !editMode.tags && (
                  <div
                    className="w-4 h-4 text-blue-500 whitespace-nowrap text-xs font-medium cursor-pointer"
                    onClick={() => handleEdit("tags")}
                  >
                    {(realTask?.tags ?? []).length > 0 ? "Edit" : "Add Tag"}
                  </div>
                )}
              </div>
              {editMode.tags && (
                <div className="flex flex-col">
                  <div className="mb-4">
                    <div className=" rounded-md h-8">
                      <SearchStringAutoCompleteDropDown
                        placeholder="Select or Add New Tag"
                        width="w-full"
                        onChange={(e) => {
                          setNewTag(e ?? "");
                        }}
                        data={
                          tags?.filter(
                            (ele) => !realTask?.tags?.includes(ele)
                          ) ?? []
                        }
                        getSelectedValue={(data) => {
                          if (data) {
                            setNewTag(data);
                            handleSave("tags", data);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end mt-2">
                    <div
                      onClick={() =>
                        newTag.length > 0 ? handleSave("tags") : {}
                      }
                      className={`text-white ${
                        newTag.length > 0
                          ? "bg-blue-500 cursor-pointer"
                          : "bg-gray-500 cursor-not-allowed"
                      } py-1  px-2 text-sm rounded-md`}
                    >
                      Add tag
                    </div>
                    <div
                      onClick={() => handleCancel("tags")}
                      className="text-gray-800 border cursor-pointer border-gray-700 ml-4 bg-white py-1 px-2 text-sm rounded-md"
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              )}
            </div>
            {nextRecurringDate && (
              <div className="inline-flex justify-between items-center bg-blue-100 px-4 py-1.5 text-sm">
                <HStack className="items-center">
                  <Clock className="h-4 w-4 text-blue-500 mr-1.5" />
                  <span className="font-medium text-blue-700 mr-1.5">
                    Next Recurring:
                  </span>
                  <span className="text-blue-800">
                    {formatDisplayDate(nextRecurringDate)}
                  </span>
                </HStack>
                {false && (
                  <HStack className="item-center gap-4">
                    {isAdmin && editMode.recurring && (
                      <span
                        onClick={() => {
                          handleRecurringSave();
                        }}
                        className="font-medium text-blue-700 mr-1.5 cursor-pointer"
                      >
                        Save
                      </span>
                    )}

                    {isAdmin && (
                      <span
                        onClick={() => {
                          !editMode.recurring
                            ? handleEdit("recurring")
                            : setEditMode({
                                ...editMode,
                                recurring: false,
                              });
                        }}
                        className="font-medium text-blue-700 mr-1.5 cursor-pointer"
                      >
                        {editMode.recurring ? "Close" : "Edit"}
                      </span>
                    )}
                  </HStack>
                )}
              </div>
            )}

            {editMode.recurring && isAdmin && (
              <div className="px-4 bg-white pt-4">
                <div className="mb-4">
                  <label
                    htmlFor="intervalMonth"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Interval Duration of Month
                  </label>
                  <Input
                    type="number"
                    name="intervalMonth"
                    id="intervalMonth"
                    value={intervalMonth}
                    onChange={(e) => setIntervalMonth(Number(e.target.value))}
                    required
                  />
                </div>
                <HStack className="w-full gap-4">
                  <div className="mb-4  flex-1">
                    <label
                      htmlFor="projectName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Recurring Due Date
                    </label>
                    <Input
                      type="number"
                      name="recurringEndDate"
                      id="recurringEndDate"
                      placeholder="Between 1 to 30"
                      value={recurringEndDate}
                      onChange={(e) =>
                        setRecurringEndDate(Number(e.target.value))
                      }
                      required
                    />
                  </div>
                  <div className="mb-4 flex-1">
                    <label
                      htmlFor="projectName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Recurring Offset Start Date
                    </label>
                    <Input
                      type="number"
                      name="recurringStartDate"
                      id="recurringStartDate"
                      placeholder="Between 1 to 30"
                      value={recurringStartDate}
                      onChange={(e) =>
                        setRecurringStartDate(Number(e.target.value))
                      }
                      required
                    />
                  </div>
                </HStack>
                <div className="mb-4 w-full">
                  <label
                    htmlFor="recurringLastDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Recuring Last Date
                  </label>
                  <Input
                    type="date"
                    name="recurringLastDate"
                    id="recurringLastDate"
                    value={recurringLastDate}
                    onChange={(e) => setRecurringLastDate(e.target.value)}
                    required
                  />
                </div>
              </div>
            )}

            {/* Tabs */}
            <div className="flex-grow flex flex-col">
              <div className="flex bg-gray-100">
                <TabButton
                  value="comments"
                  icon={MessageSquare}
                  label={
                    realTask?.groupName && !realTask?.parentTaskId
                      ? "Announcement"
                      : "Comments"
                  }
                  activeTab={activeTab}
                  onClick={setActiveTab}
                  onClose={function (): {} {
                    throw new Error("Function not implemented.");
                  }}
                />
                <TabButton
                  value="files"
                  icon={Paperclip}
                  label="Files"
                  activeTab={activeTab}
                  onClick={setActiveTab}
                  onClose={function (): {} {
                    throw new Error("Function not implemented.");
                  }}
                />
                {isAdmin && (
                  <TabButton
                    value="activity"
                    icon={Activity}
                    label="Activity"
                    activeTab={activeTab}
                    onClick={setActiveTab}
                    onClose={function (): {} {
                      throw new Error("Function not implemented.");
                    }}
                  />
                )}
              </div>
              <div className=" bg-white p-4 overflow-y-auto">
                {activeTab === "comments" && (
                  <div className="space-y-4 justify-between">
                    <div className="space-y-4 pb-40">
                      {(comments ?? [])
                        .sort((a: any, b: any) => b.updatedAt - a.updatedAt)
                        .map((comment: any, index: number) => (
                          <div
                            key={index}
                            className={`${
                              comment.id === updateComment
                                ? "bg-blue-100"
                                : "bg-gray-50"
                            } p-4 rounded-md shadow-sm hover:shadow-md transition-shadow duration-300`}
                          >
                            <HStack className="w-full justify-between">
                              <div className="flex items-center mb-2">
                                <div className="w-10 h-10 bg-gradient-to-br from-blue-400 to-indigo-500 rounded-full flex items-center justify-center text-white font-semibold mr-3 shadow-md text-sm">
                                  {comment.name.charAt(0)}
                                </div>
                                <div>
                                  <p className="font-semibold text-gray-800 text-sm">
                                    {comment.name}
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    {formatDisplayDate(comment.updatedAt) +
                                      "  |  " +
                                      extractDateTimeFromISO(comment.updatedAt)
                                        .time}
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    {comment.updatedAt === comment.createdAt
                                      ? ""
                                      : "Edited"}
                                  </p>
                                </div>
                              </div>
                              {comment.userId === authStore.id ? (
                                <OptionMenus
                                  options={[
                                    {
                                      name: "Edit Comment",
                                      onClick: () => {
                                        setUpdateComment(comment.id);
                                        setNewComment(comment.content);
                                        // setUpdateProjectDetails(true);
                                      },
                                    },
                                    {
                                      name: "Delete Comment",
                                      onClick: async () => {
                                        await deleteComment(
                                          comment.id,
                                          comment.taskId
                                        );
                                        queryClient.invalidateQueries(
                                          "getAllComment"
                                        );
                                      },
                                    },
                                  ]}
                                />
                              ) : (
                                <p></p>
                              )}
                            </HStack>

                            <p className="text-sm text-gray-700 mt-2 leading-relaxed">
                              {comment.content}
                            </p>
                          </div>
                        ))}
                      {comments && comments.length === 0 && (
                        <CommentEmptyScreen />
                      )}
                    </div>
                    <div className="mt-4 absolute pb-4 bottom-0 left-4 right-4 bg-white z-50">
                      <textarea
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="Enter your comment here..."
                        className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                        rows={3}
                      />
                      <button
                        onClick={handleAddComment}
                        className="mt-3 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 flex items-center justify-center w-full text-sm"
                      >
                        {updateComment ? (
                          <Edit className="w-5 h-5 mr-2" />
                        ) : (
                          <Send className="w-5 h-5 mr-2" />
                        )}
                        {updateComment ? "Edit Comment" : "Add Comment"}
                      </button>
                    </div>
                  </div>
                )}

                {activeTab === "files" && (
                  <div>
                    <HStack>
                      <div className="relative flex items-start px-4">
                        <div className="flex h-6 items-center">
                          <input
                            id="comments"
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            checked={showProjectFile}
                            onClick={() => {
                              setShowProjectFile(!showProjectFile);
                            }}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-900 whitespace-nowrap"
                          >
                            Show Project Attachments
                          </label>
                        </div>
                      </div>
                      <label
                        onClick={() => {
                          setModelOpen({
                            data: {
                              id: realTasks.projectId,
                              taskId: realTasks.id,
                            },
                            type: "Link",
                            state: true,
                          });
                        }}
                        className="cursor-pointer justify-end w-full whitespace-nowrap text-blue-600 px-4 py-2 rounded-md transition-colors duration-300 flex items-center  text-sm"
                      >
                        <Plus className="w-5 h-5 mr-2" />
                        Add Link
                      </label>
                    </HStack>

                    <ul className="space-y-3 mb-8">
                      {showProjectFile &&
                        projectDocuments.map(
                          (file: TaskDocument, index: any) => (
                            <FileItem
                              key={file.id}
                              file={file}
                              isProject={true}
                              isUser={true}
                              // file.userId === authStore.id }
                              onDownload={handleDownload}
                              onDelete={() =>
                                handleDelete(file.id, realTasks.id)
                              }
                              view={view}
                              projectId={realTasks.projectId}
                              taskId={realTasks.id}
                            />
                          )
                        )}
                      {taskDocuments.map((file: TaskDocument, index: any) => (
                        <FileItem
                          key={file.id}
                          file={file}
                          isUser={true}
                          // file.userId === authStore.id }
                          onDownload={handleDownload}
                          onDelete={() => handleDelete(file.id, realTasks.id)}
                          view={view}
                          projectId={realTasks.projectId}
                          taskId={realTasks.id}
                        />
                      ))}
                      {((taskDocuments.length === 0 && !showProjectFile) ||
                        (showProjectFile && projectDocuments.length === 0)) && (
                        <DocumentEmptyScreen />
                      )}
                    </ul>
                    <div className="mt-4 flex flex-row gap-4 absolute bottom-4 right-8">
                      <label
                        htmlFor="file-upload"
                        className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 flex items-center justify-center w-full text-sm"
                      >
                        <Plus className="w-5 h-5 mr-2" />
                        Add Attachments
                        <input
                          id="file-upload"
                          type="file"
                          className="hidden"
                          multiple
                          onChange={handleAddFile}
                        />
                      </label>
                    </div>
                  </div>
                )}
                {/* {activeTab === "activity" && <ComingSoonActivities />} */}
                {activeTab === "activity" && isAdmin && (
                  <div className="-m-4 max-h-96">
                    <ActivitiesSection
                      activities={history?.sort(compareHistory) ?? []}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TaskDetailPanel;

export const LabelText = ({ text }: { text: string }) => {
  return (
    <p className="text-xs pb-0.5 text-gray-500 font-medium tracking-wide leading-relaxed">
      {text}
    </p>
  );
};
