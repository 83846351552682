import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  CellClickedEvent,
  ColDef,
  GetDataPath,
  ValueFormatterFunc,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  ButtonSize,
  IconButtonType,
  IconCTAButton,
  PrimaryCTAButton,
} from "../../shared/CTAButtonComponents";
import {
  EndDate,
  ManagerName,
  ProjectName,
  StartDate,
  tableCellStyle,
  TaskDescriptionRender,
  TaskNameRender,
  TaskTagRender,
} from "./AgComponent";
import SearchInput from "../myTask/SearchInput";
import { Action, CTADropdown } from "../../shared/Dropdown";
import { Box, HStack, VStack } from "../../shared/utils";
import { agConfigDashboardSideBar } from "./AgConfig";
import { useGetOnFilterState, usePostOnFilterState } from "./AgGridCacheQuery";
import { CustomSelect } from "../myTask/UserStateChange";
import { TaskExtractModel } from "../myTask/useExtractMyTask";
import {
  Archive,
  Bell,
  BookmarkPlus,
  CopyPlus,
  Flag,
  Trash,
} from "lucide-react";
import DeleteTaskModal from "../../modal/DeleteTask";
import SideBarAddTaskPanel from "../myTask/SideBarAddTaskPanel";
import SideBarPanel from "../myTask/SideBarPanel";
import { useAuthStore } from "../../store/useAuthStore";
import MailReminderModal from "../../modal/MailReminder";
import { formatDate, formatDisplayDate } from "../../utils/date";
import { UserSelectComponent } from "../myTask/UserDropDown";
import { MultiSelectFilterTab } from "../myTask/MultiSelectTab";
import { combinedSort } from "../myTask/taskUtils";
import "./agstyle.css";
import CloudSetting from "../project/CloudIcon";
import { useTableStateManagement } from "../project/TableHook";
import ViewSwitch from "./ViewSwitch";
import CalendarPage from "../calender/CalenderPage";
import useIsMobile from "../../utils/detectDevice";
import { useGetUserProjectRoles } from "../../queries/userRole";

export interface SwitchDropDownModel {
  name: string;
  type: string;
  id: string;
}
const statuses = [
  "ALL",
  "CREATED",
  "INPROGRESS",
  "COMPLETED",
  "CANCELLED",
  "CLOSED",
];
const statuses2 = [
  "ALL",
  "CREATED",
  "INPROGRESS",
  "COMPLETED",
  // "CANCELLED",
  // "CLOSED",
];
const valueFormatter: ValueFormatterFunc = ({ value }) => {
  return value;
};
export default function AGGridSimpleTable({
  tableId,
  tasks,
  project,
}: {
  tableId: string;
  tasks: any[];
  project?: any;
}) {
  const [includedProjectName, setIncludeProjectName] = useState(false);

  const [selectedUserIds, setSelectedUserIds] = useState<any>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>(["ALL"]);
  const companyId = "";
  const isAdmin = useAuthStore.getState().role === "ADMIN";
  const { isSubscribed } = useAuthStore();
  const userId = useAuthStore.getState().id;
  const gridApi = useRef<any>(null);
  const [users, setUsers] = useState<any[]>([]);
  useEffect(() => {
    const membersMap = new Map<string | number, any>();
    tasks.forEach((task: TaskExtractModel) => {
      task.userTaskRoles.forEach((role) => {
        if (role?.id) {
          membersMap.set(role.userId, { id: role.userId, name: role.name });
        }
      });
    });

    setUsers(Array.from(membersMap.values()));
  }, [tasks]);
  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const componentsRegistery = useMemo(
    () => ({
      taskName: memo(TaskNameRender),
      taskDescription: memo(TaskDescriptionRender),
      taskEndDate: memo(EndDate),
      taskTag: memo(TaskTagRender),
      taskStartDate: memo(StartDate),
      projectName: memo(ProjectName),
      priority: memo(ProjectName),
      taskManager: memo(ManagerName),
    }),
    []
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      // flex: 1,
    }),
    []
  );

  const caseInsensitiveComparator = (valueA: any, valueB: any) => {
    const stringA = valueA.props.data
      ? valueA.props.data.toString().toLowerCase()
      : "";
    const stringB = valueB.props.data
      ? valueB.props.data.toString().toLowerCase()
      : "";

    if (stringA < stringB) {
      return -1;
    } else if (stringA > stringB) {
      return 1;
    } else {
      return 0;
    }
  };
  const { isMobile } = useIsMobile();
  const columnDefs: ColDef[] = useMemo(() => {
    const colDef: ColDef[] = [
      {
        headerName: "Description",
        field: "description",
        menuTabs: ["filterMenuTab"],
        // pinned: "left",
        filter: "agTextColumnFilter",
        sortable: false,
        hide: isMobile ? true : false,
        flex: 1,
        width: 100,
        cellRenderer: "taskDescription",
        cellStyle: tableCellStyle,
      },
      {
        headerName: "Status",
        field: "status",
        filter: "agSetColumnFilter",
        width: 100,
        minWidth: 200,
        valueFormatter: (params: any) => {
          return params.value.props?.data;
        },
        menuTabs: ["filterMenuTab"],
        sortable: true,
        comparator: caseInsensitiveComparator,
        getQuickFilterText: (params: any) => {
          return params.data.statusLabel.toLowerCase();
        },
        cellStyle: { paddingTop: "16px", lineHeight: "20px" },
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CustomSelect,
      },
      {
        headerName: "Priority",
        field: "priority",
        menuTabs: ["filterMenuTab"],

        hide: isMobile ? true : false,
        width: 100,
        cellRenderer: "priority",
        cellStyle: tableCellStyle,
      },

      {
        headerName: "Start Date",
        field: "startDate",
        filter: "agDateColumnFilter",
        menuTabs: ["filterMenuTab"],

        width: 180,
        hide: true,

        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "taskStartDate",
        cellStyle: tableCellStyle,
      },
      {
        headerName: "Due date",
        field: "endDate",
        menuTabs: ["filterMenuTab"],
        width: 180,
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "taskEndDate",
        cellStyle: tableCellStyle,
      },
      {
        headerName: "Tag",
        field: "tags",
        menuTabs: ["filterMenuTab"],
        cellRenderer: "taskTag",
        hide: true,
        cellStyle: tableCellStyle,
      },
      {
        headerName: "Assignee",
        field: "assignee",
        hide: true,
        menuTabs: ["filterMenuTab"],
        cellRenderer: "taskManager",
        cellStyle: tableCellStyle,
      },
      {
        headerName: "Manager",
        field: "manager",
        hide: true,
        cellRenderer: "taskManager",
        menuTabs: ["filterMenuTab"],
        cellStyle: tableCellStyle,
        colId: "manager",
      },
      {
        headerName: "",
        field: "quickactions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        // cellStyle: { "padding-top": "12px", "line-height": "18px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      },
    ];
    if (!project) {
      colDef.push({
        headerName: "Project Name",
        field: "projectName",
        menuTabs: ["filterMenuTab"],
        hide: true,
        cellRenderer: "projectName",
        cellStyle: tableCellStyle,
      });
    }
    return colDef;
  }, []);

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };

  async function handleAction(action: Action) {}

  const [modalOpen, setModelOpen] = useState<{
    state: boolean;
    type: "Delete" | "Reminder" | "Duplicate";
    data: any;
  }>({
    type: "Delete",
    state: false,
    data: undefined,
  });

  const groupTaskList = useMemo(
    () =>
      tasks.filter((ele: TaskExtractModel) => {
        return !ele.parentTaskId && ele.groupName;
      }),
    [tasks]
  );
  const { data: userProjectRoles } = useGetUserProjectRoles(project?.id ?? "");

  const rowData = useMemo(
    () =>
      tasks
        .filter(
          (ele: TaskExtractModel) =>
            (selectedFilters.includes(ele.status) ||
              selectedFilters.includes("ALL")) &&
            (ele.userTaskRoles.find((users) =>
              (selectedUserIds ?? []).includes(users.userId)
            ) ||
              selectedUserIds.length === 0) &&
            ele.isActive
        )
        .sort(combinedSort)
        .flatMap((task: TaskExtractModel, index: number) => {
          let actions = null;
          let isManager = !!task.userTaskRoles.find(
            (role) => role.userId === userId && role.role === "ASSIGNER"
          );
          let isAdmin = !!userProjectRoles?.find(
            (role) => role.userId === userId && role.role === "ADMIN"
          );

          const quickactions =
            isManager || isAdmin ? (
              <CTADropdown
                actions={
                  isManager || isAdmin
                    ? [
                        {
                          data: task,
                          name: "Flag",
                          icon: <Flag className="w-3 h-3" />,
                          disabled: true,
                        },
                        {
                          data: task,
                          name: "Archieve",
                          icon: <Archive className="w-3 h-3" />,
                          disabled: true,
                        },
                        {
                          data: task,
                          name: "Reminder",
                          icon: <Bell className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                        {
                          data: task,
                          name: "Duplicate",
                          icon: <CopyPlus className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                        {
                          data: task,
                          name: "Delete",
                          icon: <Trash className="w-3 h-3" />,
                          disabled: !isManager && !isAdmin,
                        },
                      ]
                    : []
                }
                onAction={(action) => {
                  if (action.name === "Delete")
                    setModelOpen({
                      type: "Delete",
                      data: action.data,
                      state: true,
                    });
                  else if (action.name === "Reminder") {
                    setModelOpen({
                      type: "Reminder",
                      data: action.data,
                      state: true,
                    });
                  } else if (action.name === "Duplicate") {
                    setModelOpen({
                      type: "Duplicate",
                      data: action.data,
                      state: true,
                    });
                  }
                }}
              />
            ) : (
              <></>
            );
          actions = (
            <CustomSelect
              isAssignee={false}
              data={task.status ?? ""}
              onChange={(data: any) => {}}
            />
          );

          let roles = task?.userTaskRoles ?? [];
          let groupRoles = [];
          let groupAction;
          let endDate = formatDate(task?.endDate ?? "");
          let startDate = formatDate(task?.startDate ?? "");
          let groupEndDate = "";
          let groupStartDate = "";

          const assignee = task.userTaskRoles.find(
            (ele) => ele.role === "ASSIGNEE"
          )?.name;

          const groupTask = groupTaskList.find(
            (oldTask) => oldTask.id === task.parentTaskId
          );

          if (groupTask) {
            groupRoles = groupTask?.userTaskRoles ?? [];
            groupAction = (
              <CustomSelect
                isAssignee={false}
                data={groupTask.status ?? ""}
                onChange={(data: any) => {}}
              />
            );
            groupEndDate = formatDate(groupTask?.endDate ?? "");
            groupStartDate = formatDate(groupTask?.startDate ?? "");
          }

          return includedProjectName && !project
            ? //includedProjectName
              [
                {
                  id: task.id,
                  orgHierarchy: [task.projectName],
                  taskName: {
                    name: task.projectName,
                    type: task.companyName,
                  },
                  tooltipTaskName: task.name,
                  isRecurring: task.isRecurring ?? false,
                  description: task.projectDescription,
                  endDate: ``,
                  name: task.name,
                  tags: "",
                  priority: "",
                  projectId: task.projectId,
                  projectName: "",
                  projectDescription: task.projectDescription,
                  companyName: task.companyName,
                  projectSpecificStatusId: task.projectSpecificStatusId,
                  startDate: "",
                  status: "",
                  statusLabel: "",
                  quickactions: "",
                  isRead: "",
                  manager: "-",
                  assignee: "-",
                },
                {
                  id: task.id,
                  orgHierarchy: [task.projectName, task.name],
                  taskName: {
                    name: task.name,
                    type: task.companyName,
                  },
                  projectDescription: task.projectDescription,
                  tooltipTaskName: task.name,
                  isRecurring: task.isRecurring ?? false,
                  description: task.description,
                  endDate: `${endDate}`,
                  name: task.name,

                  tags: task.tags,
                  priority: task.priority,
                  projectId: task.projectId,
                  projectName: task.projectName,
                  companyName: task.companyName,
                  projectSpecificStatusId: task.projectSpecificStatusId,
                  startDate: `${startDate}`,
                  status: actions,
                  statusLabel: task.status,
                  quickactions: quickactions,
                  isRead: task.isRead ?? false,
                  manager: roles
                    .filter((user) => user.role === "ASSIGNER")
                    .map((user) => ({
                      id: user.id,
                      name: user.name,
                    })),
                  assignee: roles
                    .filter((user) => user.role === "ASSIGNEE")
                    .map((user) => ({
                      id: user.id,
                      name: user.name,
                    })),
                },
              ]
            : task.groupName && groupTask
            ? [
                {
                  id: groupTask.id,
                  isGroup: true,
                  orgHierarchy: [groupTask.groupName],
                  taskName: {
                    name: groupTask.name,
                    type: groupTask.companyName,
                  },
                  isRecurring: groupTask.isRecurring ?? false,
                  projectDescription: groupTask.projectDescription,
                  tooltipTaskName: groupTask.name,
                  description: groupTask.description,
                  endDate: `${groupEndDate}`,
                  name: groupTask.name,
                  tags: groupTask.tags,
                  priority: groupTask.priority,
                  projectId: groupTask.projectId,
                  projectName: groupTask.projectName,
                  companyName: groupTask.companyName,
                  projectSpecificStatusId: groupTask.projectSpecificStatusId,
                  startDate: `${groupStartDate}`,
                  status: actions,
                  statusLabel: groupTask.status,
                  quickactions: quickactions,
                  isRead: groupTask.isRead ?? false,
                  manager: groupRoles
                    .filter((user: any) => user.role === "ASSIGNER")
                    .map((user: any) => ({
                      id: user.id,
                      name: user.name,
                    })),
                  assignee: groupRoles
                    .filter((user: any) => user.role === "ASSIGNEE")
                    .map((user: any) => ({
                      id: user.id,
                      name: user.name,
                    })),
                },

                {
                  isGroup: true,
                  id: task.id,
                  orgHierarchy: [task.groupName, assignee ?? "None"],
                  taskName: {
                    name: task.name,
                    type: task.companyName,
                  },
                  projectDescription: task.projectDescription,
                  tooltipTaskName: task.name,
                  description: task.description,
                  isRecurring: task.isRecurring ?? false,
                  endDate: `${endDate}`,
                  name: task.name,
                  tags: task.tags,
                  priority: task.priority,
                  projectId: task.projectId,
                  projectName: task.projectName,
                  companyName: task.companyName,
                  projectSpecificStatusId: task.projectSpecificStatusId,
                  startDate: `${startDate}`,
                  status: actions,
                  statusLabel: task.status,
                  quickactions: quickactions,
                  isRead: task.isRead ?? false,
                  manager: roles
                    .filter((user) => user.role === "ASSIGNER")
                    .map((user) => ({
                      id: user.id,
                      name: user.name,
                    })),
                  assignee: roles
                    .filter((user) => user.role === "ASSIGNEE")
                    .map((user) => ({
                      id: user.id,
                      name: user.name,
                    })),
                },
              ]
            : {
                id: task.id,
                orgHierarchy: [task.name],
                taskName: {
                  name: task.name,
                  type: task.companyName,
                },
                projectDescription: task.projectDescription,
                tooltipTaskName: task.name,
                description: task.description,
                endDate: `${endDate}`,
                isRecurring: task.isRecurring ?? false,
                name: task.name,
                tags: task.tags,
                priority: task.priority,
                projectId: task.projectId,
                projectName: task.projectName,
                companyName: task.companyName,
                projectSpecificStatusId: task.projectSpecificStatusId,
                startDate: `${startDate}`,
                status: actions,
                statusLabel: task.status,
                quickactions: quickactions,
                isRead: task.isRead ?? false,
                manager: roles
                  .filter((user) => user.role === "ASSIGNER")
                  .map((user) => ({
                    id: user.id,
                    name: user.name,
                  })),
                assignee: roles
                  .filter((user) => user.role === "ASSIGNEE")
                  .map((user) => ({
                    id: user.id,
                    name: user.name,
                  })),
              };
        }),
    [
      tasks,
      selectedFilters,
      selectedUserIds,
      includedProjectName,
      userProjectRoles,
    ]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.78) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.94) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.7) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }
  const [pageLoaded, setPageLoaded] = useState(false);
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
    refetch,
  } = useTableStateManagement(gridApi, tableId, pageLoaded);

  useEffect(() => {
    setPageLoaded(!pageLoaded);
  }, []);
  const [isUpdateProjectDetails, setUpdateProjectDetails] = useState<{
    state: boolean;
    task?: TaskExtractModel;
  }>({
    state: false,
    task: undefined,
  });

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      // if (cellParams.column.getColId() === "taskName") {
      setUpdateProjectDetails({
        task: template,
        state: true,
      });
      // }
    }
  };
  const contextRef = useRef<HTMLDivElement>(null);

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleUserChange = (newSelectedUsers: any) => {
    setSelectedUserIds(newSelectedUsers);
  };
  const gridContainerRef = useRef(null);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);

    uploadFilter();
  };

  const [isCreateTask, setCreateTask] = useState(false);

  const handleFilterChange = (newFilters: any) => {
    setSelectedFilters(newFilters);
  };

  const getDataPath = useCallback<GetDataPath>((data) => data.orgHierarchy, []);
  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: "Name",

      width: isMobile ? 120 : 300,
      pinned: isMobile ? null : "left",
      sort: "asc",
      sortable: true,
      cellStyle: tableCellStyle,
      valueGetter: (params) =>
        params.data.orgHierarchy[params.data.orgHierarchy.length - 1],
      cellRenderer: "agGroupCellRenderer",
      menuTabs: ["filterMenuTab"],
      // pinned: "left",
      filter: "agTextColumnFilter",
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: memo(TaskNameRender),
      },
    };
  }, []);
  const [view, setView] = useState("list");
  return (
    <>
      {modalOpen?.data && modalOpen?.type === "Duplicate" && (
        <SideBarAddTaskPanel
          taskData={modalOpen.data}
          projectIdData={modalOpen.data.projectId}
          isOpen={modalOpen.state}
          onClose={() => {
            setModelOpen({
              state: false,
              type: "Duplicate",
              data: null,
            });
          }}
          onCreateProject={() => {
            setModelOpen({
              state: false,
              type: "Duplicate",
              data: null,
            });
          }}
        />
      )}

      {modalOpen?.data && modalOpen?.type === "Delete" && (
        <DeleteTaskModal
          taskIdData={modalOpen?.data?.id}
          projectId={modalOpen.data.projectId}
          taskNameData={modalOpen?.data.name}
          isOpen={modalOpen.state}
          onClose={() => {
            setModelOpen({
              state: false,
              type: "Delete",
              data: null,
            });
          }}
        />
      )}
      {modalOpen?.data && modalOpen?.type === "Reminder" && (
        <MailReminderModal
          taskIdData={modalOpen?.data?.id}
          projectId={modalOpen.data.projectId}
          taskNameData={modalOpen?.data.name}
          isOpen={modalOpen.state}
          onClose={() => {
            setModelOpen({
              state: false,
              type: "Reminder",
              data: null,
            });
          }}
        />
      )}
      {isUpdateProjectDetails.state && (
        <SideBarPanel
          tags={[]}
          realTasks={isUpdateProjectDetails.task}
          // task={isUpdateProjectDetails.task}
          open={isUpdateProjectDetails.state ?? false}
          onClose={() => {
            setUpdateProjectDetails({ state: false, task: undefined });
          }}
          isAdmin={true}
        />
      )}
      {(project?.id ?? "") && isAdmin && (
        <SideBarAddTaskPanel
          isOpen={isCreateTask}
          onClose={() => {
            setCreateTask(false);
          }}
          onCreateProject={() => {
            setCreateTask(false);
          }}
          projectIdData={project.id ?? ""}
        />
      )}
      <div className="px-8 py-0">
        <HStack className={`justify-between items-baseline`}>
          <VStack className="pt-2 sm:mx-0 -mx-8 sm:mb-0 mb-1">
            <ViewSwitch view={view} setView={setView} />
          </VStack>
          <VStack className="sm:flex hidden justify-end -mr-8">
            <div></div>
            {view === "list" ? (
              <MultiSelectFilterTab
                tabs={isSubscribed ? statuses : statuses2}
                onChange={handleFilterChange}
              />
            ) : (
              <div className="h-8"></div>
            )}
          </VStack>
        </HStack>
      </div>
      {view === "list" ? (
        <>
          <HStack className="flex-col  items-start justify-start py-2 bg-white border rounded-md rounded-tr-none lg:flex-row lg:justify-between lg:items-center border-borderColor shadow-box w-full">
            <HStack className="pl-8 gap-4 items-center">
              <SearchInput
                placeholder={`Search Task`}
                onChange={(e: any) => {
                  gridApi.current.api.setQuickFilter(e.target.value);
                }}
              />
              {isAdmin && isSubscribed && (
                <UserSelectComponent
                  users={users}
                  onChange={handleUserChange}
                />
              )}
              <div className="sm:block">
                <CloudSetting
                  tableId={tableId}
                  isLoading={isSaving}
                  filterCondition={filterCondition}
                  setFilterCondition={setFilterCondition}
                />
              </div>
            </HStack>
            <HStack className="items-center justify-between gap-4 px-8 py-2 lg:w-auto lg:justify-between w-full">
              <HStack className="gap-4">
                {/* <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                  Show Password
                </label>
              </div>
            </div> */}

                {!project && isSubscribed && (
                  <div className="flex flex-row items-center cursor-pointer gap-2">
                    <input
                      type="checkbox"
                      aria-describedby="groupproject"
                      name="groupproject"
                      id="groupproject"
                      className="cursor-pointer"
                      checked={includedProjectName}
                      onChange={() =>
                        setIncludeProjectName(!includedProjectName)
                      }
                    />
                    <label
                      htmlFor="groupproject"
                      className="cursor-pointer text-sm text-gray-700"
                    >
                      Group Project
                    </label>
                  </div>
                )}

                <IconCTAButton
                  value={"View"}
                  onClick={() => openToolPanel("columns")}
                  selected={isColumnOpen}
                  iconName={"basil:settings-adjust-solid"}
                  iconClassName="block"
                  textClassName="sm:block hidden"
                  className={`sm:px-4 px-1 font-medium items-center flex flex-row ${
                    isColumnOpen ? "text-orange-501" : "text-gray-400"
                  }`}
                />
                {/* <IconCTAButton
              value={"Filters"}
              onClick={() => openToolPanel("filters")}
              iconName={"material-symbols:filter-alt"}
              selected={isFilterOpen}
              textClassName="sm:block hidden"
              className={`sm:px-4 px-1  font-medium items-center flex flex-row ${
                isFilterOpen ? "text-orange-501" : "text-gray-400"
              }`}
            /> */}
                {isAdmin && project && (
                  <PrimaryCTAButton
                    onClick={() => setCreateTask(true)}
                    buttonSize={ButtonSize.MEDIUM}
                  >
                    <span className="hidden lg:block">Add Task</span>
                    <BookmarkPlus className="w-4 h-4 ml-2" />
                  </PrimaryCTAButton>
                )}
              </HStack>
            </HStack>
          </HStack>

          <HStack className="justify-between relative w-full">
            <Box
              style={{
                height: setTableHeight(),
              }}
              className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
            >
              <AgGridReact
                sideBar={agConfigDashboardSideBar}
                onGridReady={(params) => {
                  // Store the grid API referen
                  gridApi.current = params;
                }}
                components={componentsRegistery}
                onColumnMoved={uploadColumn}
                alwaysShowHorizontalScroll
                groupDefaultExpanded={-1}
                getDataPath={getDataPath}
                treeData
                autoGroupColumnDef={autoGroupColumnDef}
                alwaysMultiSort
                animateRows={true}
                defaultColDef={defaultColDef}
                onFilterChanged={onAgGridFilterChanged}
                onColumnEverythingChanged={uploadColumn}
                rowData={rowData}
                onCellClicked={handleCellClick}
                columnDefs={columnDefs}
                pagination={false}
                // enableCharts={true}
                // enableRangeSelection={true}
                suppressRowTransform={true}
                suppressCopyRowsToClipboard={true}
                suppressCopySingleCellRanges={true}
                suppressCellFocus={true}
                suppressMenuHide={false}
                onColumnResized={uploadColumn}
                tooltipShowDelay={1000}
                tooltipInteraction={true}
                rowClass={"border-t border-dashed"}
                gridOptions={gridOptions}
                overlayNoRowsTemplate={
                  ' <div style="text-align: center; padding: 40px; "> <div style="display: inline-block;"><div style="font-size: 18px; color: #555; margin-bottom: 8px;">Table is empty</div><div style="font-size: 14px; color: #777;">No data available at the moment</div></div></td>'
                }
                getRowStyle={(params) => {
                  if (params.data.isRead) {
                    return { background: "#F2F6FB" };
                  } else {
                    return { background: "#ffffff" };
                  }
                }}
              ></AgGridReact>
            </Box>
          </HStack>
        </>
      ) : (
        <CalendarPage projectId={project?.id ?? ""} />
      )}
    </>
  );
}
