import { useEffect, useState } from "react";
import {
  useGetUserProfile,
  useUpdateUserProfile,
} from "../../queries/userRole";
import { toast } from "react-toastify";
import { queryClient } from "../../queries/client";

export default function Example() {
  const [firstName, setFirstName] = useState("");
  const [mail, setMail] = useState("");
  const { data } = useGetUserProfile();
  useEffect(() => {
    if (data) {
      setFirstName(data?.name ?? "");
      setMail(data?.emailId ?? "");
    }
  }, [data]);
  const { mutate: updateProfile } = useUpdateUserProfile();
  return (
    <>
      <div className="space-y-12 bg-white rounded-xl shadow-box border border-borderColor p-12">
        {data && (
          <div className="pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Profile Information
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    id="first-name"
                    name="first-name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    type="text"
                    autoComplete="given-name"
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    value={mail}
                    disabled
                    onChange={(e) => {
                      setMail(e.target.value);
                    }}
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-6  flex items-center justify-end gap-x-6">
          {firstName !== data?.name && (
            <button
              onClick={() => {
                if (firstName) {
                  updateProfile(
                    {
                      userId: data.userId ?? "",
                      name: firstName,
                    },
                    {
                      onSuccess() {
                        toast("User profile updated", {
                          type: "success",
                          autoClose: 2000,
                        });
                        queryClient.invalidateQueries("getUserProfile");
                      },
                      onError(err) {
                        toast("User profile updated", {
                          type: "success",
                          autoClose: 2000,
                        });
                      },
                    }
                  );
                }
              }}
              className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Save Changes
            </button>
          )}
        </div>
      </div>
    </>
  );
}
