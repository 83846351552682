import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Popover, Transition } from "@headlessui/react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import _ from "lodash";
import {
  useDeleteTableState,
  useGetTableFilterState,
  usePostTableFilterState,
} from "../../queries/login";
import { useAuthStore } from "../../store/useAuthStore";
import { FilterConditionModel } from "../../api/project";
import { TableId } from "./TableIdConstants";

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const Switch: React.FC<SwitchProps> = ({ checked, onChange }) => (
  <motion.div
    className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
      checked ? "bg-blue-500" : "bg-gray-300"
    }`}
    onClick={() => onChange(!checked)}
    animate={{ backgroundColor: checked ? "#3B82F6" : "#D1D5DB" }}
  >
    <motion.div
      className="bg-white w-4 h-4 rounded-full shadow-md"
      animate={{
        x: checked ? 22 : 2,
      }}
      transition={{ type: "spring", stiffness: 700, damping: 30 }}
    />
  </motion.div>
);

interface SavingStatusIconProps {
  isLoading: boolean;
}

const SavingStatusIcon: React.FC<SavingStatusIconProps> = ({ isLoading }) => {
  const [showSaved, setShowSaved] = useState<boolean>(false);

  useEffect(() => {
    let timer: any;
    if (!isLoading) {
      timer = setTimeout(() => {
        setShowSaved(true);
        setTimeout(() => setShowSaved(false), 2000);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isLoading]);

  return (
    <div className="flex items-center space-x-2">
      <div
        className={`transition-all duration-300 ${
          isLoading ? "animate-spin" : ""
        }`}
      >
        <Icon
          icon={isLoading ? "tdesign:refresh" : "basil:cloud-check-outline"}
          className={`${
            isLoading ? "text-blue-500 w-5 h-5" : "text-green-500 w-6 h-6"
          }`}
        />
      </div>
      <span
        className={`transition-opacity whitespace-nowrap duration-300 ${
          isLoading || showSaved ? "opacity-100" : "opacity-0"
        }`}
      >
        {isLoading ? "Saving..." : showSaved ? "Saved" : ""}
      </span>
    </div>
  );
};

interface CloudSettingProps {
  tableId: string;
  isLoading: boolean;
  filterCondition: FilterConditionModel;
  setFilterCondition: (func: any) => void;
}

const CloudSetting: React.FC<CloudSettingProps> = ({
  tableId,
  isLoading,
  filterCondition,
  setFilterCondition,
}) => {
  const [show, setShown] = useState<boolean>(false);
  const { mutate: deleteTableState } = useDeleteTableState();
  const { data: tableState } = useGetTableFilterState(tableId);
  const { mutate: postOnFilter } = usePostTableFilterState();

  const debouncedSearch = useCallback(
    _.debounce((isColumnSave: boolean, isFilterSave: boolean) => {
      postOnFilter({
        tableId: tableId,
        companyId: "",
        filterData: {
          isColumnSave,
          isFilterSave,
          filterState: tableState?.filterState,
          columnState: tableState?.columnState,
        },
      });
    }, 0),
    [filterCondition, tableState, postOnFilter]
  );

  const handleMouseEvents = (isShown: boolean) => () => setShown(isShown);

  const handleColumnViewChange = (): void => {
    const newColumnSave = !filterCondition.isColumnSave;
    debouncedSearch(newColumnSave, filterCondition.isFilterSave);
    setFilterCondition((prev: any) => ({
      ...prev,
      isColumnSave: newColumnSave,
    }));
  };

  const handleFilterViewChange = (): void => {
    const newFilterSave = !filterCondition.isFilterSave;
    debouncedSearch(filterCondition.isColumnSave, newFilterSave);
    setFilterCondition((prev: any) => ({
      ...prev,
      isFilterSave: newFilterSave,
    }));
  };

  const handleClearAllStates = (): void => {
    deleteTableState(tableId, {
      onSuccess: () => {
        window.location.reload();
      },
    });
  };

  return (
    <Popover className="relative">
      <Popover.Button
        onClick={handleMouseEvents(!show)}
        onMouseEnter={handleMouseEvents(true)}
        onMouseLeave={handleMouseEvents(false)}
        className={`-m-2.5 p-2.5 ${
          show ? "text-gray-700" : "text-gray-400 hover:text-gray-500"
        } ring-0 border-0 focus:ring-0 border-white`}
      >
        <span className="sr-only">Settings</span>
        <SavingStatusIcon isLoading={isLoading} />
      </Popover.Button>

      <Transition
        show={show}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          onMouseEnter={handleMouseEvents(true)}
          onMouseLeave={handleMouseEvents(false)}
          className="absolute z-10 mt-5 flex w-screen max-w-max -right-8"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="w-80 bg-gradient-to-br from-blue-50 to-purple-50 rounded-lg shadow-lg overflow-hidden border border-blue-200"
          >
            <div className="bg-gradient-to-l from-gradientTo to-gradientFrom p-4 flex items-center space-x-2">
              <Icon
                icon="mdi:table-cog"
                className="text-white"
                width="24"
                height="24"
              />
              <div>
                <h2 className="text-white font-bold text-lg">
                  Table State Control
                </h2>
                <p className="text-blue-100 text-xs">Manage view preferences</p>
              </div>
            </div>
            <div className="p-5 space-y-4">
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-white p-3 rounded-lg shadow-sm"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium text-gray-700">Column View</span>
                  <Switch
                    checked={filterCondition.isColumnSave}
                    onChange={handleColumnViewChange}
                  />
                </div>
                <p className="text-xs text-gray-500">
                  Save your custom column layouts
                </p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-white p-3 rounded-lg shadow-sm"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium text-gray-700">Filter View</span>
                  <Switch
                    checked={filterCondition.isFilterSave}
                    onChange={handleFilterViewChange}
                  />
                </div>
                <p className="text-xs text-gray-500">
                  Preserve your applied filters
                </p>
              </motion.div>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleClearAllStates}
                className="w-full bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-lg transition duration-300 ease-in-out flex items-center justify-center space-x-2 shadow-sm"
              >
                <Icon icon="mdi:trash-can-outline" width="16" height="16" />
                <span>Clear All Saved States</span>
              </motion.button>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="flex items-start space-x-2 text-xs text-gray-600 mt-4 bg-blue-100 p-3 rounded-lg border border-blue-200"
              >
                <Icon
                  icon="mdi:cloud-sync"
                  className="text-blue-500 flex-shrink-0 mt-0.5"
                  width="16"
                  height="16"
                />
                <p>
                  Your preferences are automatically saved in the cloud for
                  consistent views across all your devices.
                </p>
              </motion.div>
            </div>
          </motion.div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default CloudSetting;
