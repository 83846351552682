import { ChevronDown } from "lucide-react";
import React from "react";

const StateBadge = ({
  state,
  isPreview=false,
  onClick,
}: {
  state: string;
  isPreview?: boolean;
  onClick: () => void;
}) => {

  const colors: any = {
    OPEN: "bg-blue-100 text-blue-800",
    "IN PROGRESS": "bg-yellow-100 text-yellow-800",
    COMPLETE: "bg-green-100 text-green-800",
    CANCEL: "bg-red-100 text-red-800",
    CLOSE: "bg-green-600 text-white",
  };
  return (
    <button
      onClick={onClick}
      className={`inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium ${colors[state]}`}
    >
      {state}
      {!isPreview && <ChevronDown className="w-4 h-4" />}
    </button>
  );
};

export default StateBadge;
