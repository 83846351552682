import React from "react";
import { getDates } from "../aggrid/AgComponent";
import { Icon } from "@iconify/react";

const OverDue = ({ task, isShort }: { task: any; isShort?: boolean }) => {
  return (
    <>
      {task.status.toLowerCase() !== "closed" &&
        getDates(task.endDate) !== null && (
          <span
            className={`inline-flex whitespace-nowrap w-fit items-center gap-x-1.5 rounded-md  px-2 py-0.5 text-[9px] leading-0 font-medium ${
              (getDates(task.endDate) ?? 0) > 0
                ? "text-yellow-600 bg-transparent"
                : "text-red-600 bg-transparent"
            }`}
          >
            <Icon
              icon={"lsicon:overtime-filled"}
              className="text-red-500 w-3 h-3"
            />
            {isShort
              ? (getDates(task.endDate) ?? 0) > 0
                ? `${getDates(task.endDate)} d Left`
                : (getDates(task.endDate) ?? 0) === 0
                ? `Due Today`
                : `${Math.abs(getDates(task.endDate) ?? 0)} d`
              : (getDates(task.endDate) ?? 0) > 0
              ? `${getDates(task.endDate)} Days Left`
              : (getDates(task.endDate) ?? 0) === 0
              ? `Due Today`
              : `${Math.abs(getDates(task.endDate) ?? 0)} d Overdue`}
          </span>
        )}
    </>
  );
};

export default OverDue;
