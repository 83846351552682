import { Icon } from "@iconify/react";
import React, { ButtonHTMLAttributes } from "react";
import { HStack } from "./utils";
import { Action, CTADropdown } from "./Dropdown";
import { Trash2 } from "lucide-react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

type SecondaryCTAButtonProps = {
  buttonSize?: ButtonSize;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
} & ButtonProps;

export enum ButtonSize {
  SMALL = "px-1.5 py-1 text-xs1 leading-[18px]",
  MEDIUM = "px-3 py-2.5 text-xs2 leading-4",
}

export function SecondaryCTAButton({
  className,
  loading = false,
  disabled = false,
  type = "button",
  buttonSize = ButtonSize.MEDIUM,
  children,
  ...rest
}: SecondaryCTAButtonProps): React.ReactElement {
  const buttonClassName = `inline-flex  rounded-md bg-transparent hover:shadow:md items-center cursor-pointer rounded-sm whitespace-nowrap font-semibold shadow-box ${buttonSize}  ${
    disabled
      ? `bg-gray-100 border border-gray-300 text-gray-300 cursor-not-allowed ${className}`
      : `border border-primary text-primary hover:bg-primary/20 focus:outline-none ${className}`
  }`;

  return (
    <button
      {...rest}
      disabled={disabled}
      className={buttonClassName}
      type={type}
    >
      {loading ? <LoaderText buttonSize={buttonSize} /> : children}
    </button>
  );
}

type PrimaryCTAButtonProps = {
  buttonSize?: ButtonSize;
  className?: string;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
} & ButtonProps;

export function PrimaryCTAButton({
  className,
  loadingText,
  loading = false,
  type = "button",
  buttonSize = ButtonSize.SMALL,
  disabled = false,
  children,
  ...rest
}: PrimaryCTAButtonProps): React.ReactElement {
  const buttonClassName = `inline-flex items-center text-center cursor-pointer ${buttonSize} rounded-md border border-transparent hover:shadow-md  whitespace-nowrap font-semibold text-white shadow-box  focus:outline-none ${
    disabled
      ? "bg-gray-300 cursor-not-allowed"
      : // : `${className} bg-primary hover:bg-primary/80 rounded-md`
        `${className} bg-blue-600 hover:bg-blue-500 hover:bg-blue-500 rounded-md`
  }`;

  return (
    <button
      {...rest}
      disabled={disabled}
      className={buttonClassName}
      type={type}
    >
      {loading ? (
        <LoaderText buttonSize={buttonSize} text={loadingText} />
      ) : (
        children
      )}
    </button>
  );
}

export function LoaderText({
  buttonSize,
  text,
}: {
  buttonSize?: ButtonSize;
  text?: string;
}) {
  const loadText = text ?? "Please wait...";
  return (
    <HStack className="cursor-wait mx-auto">
      <p
        className="indicator-progress text-center leading-none pt-0.5"
        style={{ display: "block" }}
      >
        {loadText}
      </p>
      <Icon
        className="animate-spin"
        icon="lucide:loader-2"
        width={buttonSize === ButtonSize.MEDIUM ? 16 : 14}
      />
    </HStack>
  );
}

export function TertiaryCTAButton({
  className,
  type = "button",
  buttonSize = ButtonSize.MEDIUM,
  ...rest
}: PrimaryCTAButtonProps): React.ReactElement {
  const buttonClassName = `inline-flex items-center border border-[#F9F9F9] cursor-pointer ${buttonSize} rounded-md border border-transparent bg-tertiaryBg whitespace-nowrap font-semibold text-tertiaryText shadow-box hover:bg-tertiaryBgHover focus:outline-none ${className}`;

  return <button {...rest} className={buttonClassName} type={type} />;
}

export enum IconSize {
  S = 12,
  M = 18,
  L = 24,
}

export enum IconPlacement {
  LEFT,
  RIGHT,
}

export enum IconButtonType {
  FILLED = "bg-blue-500 text-white shadow-sm font-semibold",
  OUTLINED = "font-semibold",
  NONE = "font-medium",
}

type IconCTAButtonProps = {
  value?: string;
  buttonSize?: ButtonSize;
  selected?: boolean;
  iconName?: string;
  buttonType?: IconButtonType;
  className?: string;
  iconClassName?: string;
  iconSize?: IconSize;
  textClassName?: string;
  iconPlacement?: IconPlacement;
} & ButtonProps;

export function IconCTAButton(props: IconCTAButtonProps) {
  const {
    value,
    selected = false,
    iconName,
    buttonType = IconButtonType.OUTLINED,
    buttonSize = ButtonSize.SMALL,
    className,
    iconClassName,
    textClassName,
    iconSize = IconSize.S,
    iconPlacement = IconPlacement.LEFT,
    ...rest
  } = props;
  const placementClass =
    iconPlacement === IconPlacement.RIGHT ? "flex-row-reverse" : "";
  const selectedStyle = selected
    ? "text-white bg-blue-500"
    : "text-secondary  bg-transparent";
  const buttonClassName = `${buttonType} inline-flex hover:bg-blue-500 tracking-wide hover:text-white border border-gray-400 items-center gap-x-1 rounded-md ${buttonSize} font-medium ${className} ${placementClass} ${
    buttonType === IconButtonType.OUTLINED ? selectedStyle : "border-none"
  }`;
  return (
    <button {...rest} className={buttonClassName} type={"button"}>
      {iconName && (
        <Icon
          aria-hidden="true"
          icon={iconName}
          className={iconClassName}
          height={iconSize}
          width={iconSize}
        />
      )}
      <span className={textClassName}>{value}</span>
    </button>
  );
}

type LoaderButtonProps = {
  buttonSize?: ButtonSize;
  className?: string;
} & ButtonProps;

export function LoaderCTAButton({
  className,
  type = "button",
  buttonSize = ButtonSize.MEDIUM,
  ...rest
}: LoaderButtonProps): React.ReactElement {
  const _className = `inline-flex items-center cursor-not-allowed ${buttonSize} rounded-sm border border-transparent bg-primary px-3 py-2.5 text-sm font-medium leading-4 text-white shadow-box hover:bg-primary/70 ${className}`;

  return (
    <button {...rest} className={_className} type={type} disabled>
      <HStack>
        <p className="indicator-progress" style={{ display: "block" }}>
          Please wait
        </p>
        <Icon className="animate-spin ml-2" icon="lucide:loader-2" width={18} />
      </HStack>
    </button>
  );
}

interface ExportImportButtonProps {
  actions: Action[];
  onAction: (action: Action) => void;
}

export const ExportImportButton: React.FC<ExportImportButtonProps> = ({
  actions,
  onAction,
}) => (
  <CTADropdown
    className="items-center"
    dropdownClassName="bottom-auto"
    actions={actions}
    onAction={onAction}
    render={() => (
      <div className="flex items-center px-3 py-2.5 font-medium gap-x-2 rounded text-sm text-[#464E5F] bg-[#F5F9FB] hover:bg-[#464E5F]/20">
        <p>Export</p>
        <Icon height={24} icon="ri:arrow-drop-down-line" className="inline" />
      </div>
    )}
  />
);

type DeleteCTAButtonProps = {
  buttonSize?: ButtonSize;
  className?: string;
  loading?: boolean;
  text?: string;
  disabled?: boolean;
} & ButtonProps;

export const DeleteButton = ({
  className,
  text,
  type = "button",
  disabled = false,
  children,
  ...rest
}: DeleteCTAButtonProps): React.ReactElement => {
  return (
    <button
      disabled={disabled}
      {...rest}
      className={`flex items-center justify-center px-4 py-2 ${
        disabled
          ? "bg-gray-500 hover:bg-gray-400 cursor-not-allowed"
          : "bg-red-500 hover:bg-red-600"
      } text-white rounded-md shadow-md  focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition-colors duration-200 ease-in-out group`}
    >
      <Trash2 className="w-5 h-5 mr-2 group-hover:animate-wiggle" />
      <span className="font-semibold">{text}</span>
    </button>
  );
};

export const EventButton = ({
  className,
  text,
  type = "button",
  disabled = false,
  children,
  ...rest
}: DeleteCTAButtonProps): React.ReactElement => {
  return (
    <button
      disabled={disabled}
      {...rest}
      className={`flex items-center justify-center px-4 py-2 ${
        disabled
          ? "bg-gray-500 hover:bg-gray-400 cursor-not-allowed"
          : "bg-blue-500 hover:bg-blue-600"
      } text-white rounded-md shadow-md  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 ease-in-out group`}
    >
      {/* <Trash2 className="w-5 h-5 mr-2 group-hover:animate-wiggle" /> */}
      <span className="font-semibold">{text}</span>
    </button>
  );
};
