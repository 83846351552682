import { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Input } from "../shared/InputField";
import { HStack } from "../shared/utils";
import { toast } from "react-toastify";
import { Trash } from "lucide-react";
import { deleteProject } from "../api/project";
import { queryClient } from "../queries/client";
import { useNavigate } from "react-router-dom";
import { DeleteButton } from "../shared/CTAButtonComponents";

const DeleteProjectModal = ({
  projectIdData,
  projectName,
  isOpen,
  onClose,
  onCreateProject,
}: {
  projectIdData: any;
  projectName: any;
  isOpen: any;
  onClose: any;
  onCreateProject: any;
}) => {
  const [taskName, setTaskName] = useState("");

  const navigate = useNavigate();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    deleteProject(projectIdData)
      .then((data) => {
        queryClient.invalidateQueries("getAllProjects");
        queryClient.invalidateQueries("overview");
        navigate("/tasks");
        toast("Project Delete Successfully", {
          type: "success",
          autoClose: 2000,
        });
      })
      .catch((e) => {
        toast(e.response.data.message, {
          type: "error",
          autoClose: 2000,
        });
      });
    onClose();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <HStack className="items-center">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <HStack className="items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <Trash
                        className="h-6 w-6 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-base pb-2 pl-2 pt-4 tracking-wide font-semibold leading-6 text-gray-900"
                    >
                      Delete Project
                      <p className="text-gray-500 tracking-normal font-medium text-xs">
                        This project will be deleted, along with all of its
                        files, users, tasks, and activity.
                      </p>
                    </Dialog.Title>
                  </HStack>
                </HStack>
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center sm:ml-4 w-full sm:mt-0 sm:text-left">
                    <form onSubmit={handleSubmit} className="mt-4">
                      <div className="mb-4">
                        <label
                          htmlFor="projectName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Enter the project name "{projectName}" to continue:
                        </label>
                        <Input
                          type="text"
                          name="taskName"
                          id="taskName"
                          value={taskName}
                          onChange={(e) => setTaskName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-4">
                        <DeleteButton
                          disabled={taskName !== projectName}
                          text={"Delete Permanently"}
                          onClick={() => {}}
                        />

                        <button
                          type="button"
                          className="flex items-center justify-center px-4 py-2 w-full rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteProjectModal;
