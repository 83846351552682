function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function HomeTab({
  type,
  tabs,
  setType,
}: {
  type: string;
  tabs: string[];
  setType: any;
}) {
  return (
    <div className="block">
      <nav
        aria-label="Tabs"
        className="isolate flex divide-x divide-gray-200 rounded-lg shadow cursor-pointer"
      >
        {tabs.map((tab, tabIdx) => (
          <a
            onClick={() => {
              setType(tab);
            }}
            key={tab}
            // href={tab}
            aria-current={tab ? "page" : undefined}
            className={classNames(
              tab ? "text-gray-900" : "text-gray-500 hover:text-gray-700",
              tabIdx === 0 ? "rounded-l-lg" : "",
              tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
              "group relative min-w-0 flex-1 overflow-hidden bg-white sm:px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            )}
          >
            <span>{tab}</span>
            <span
              aria-hidden="true"
              className={classNames(
                tab === type ? "bg-blue-500" : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </a>
        ))}
      </nav>
    </div>
  );
}
