const DocumentEmptyScreen = ({comingSoon}:{comingSoon?:boolean}) => {
  return (
    <div>
      <div className="w-60 mx-auto pt-16 justify-center items-center">
        <img src="/documents.png" />
      </div>
      <p className="text-gray-900 font-medium w-72 text-center mx-auto">
        Task files go here
      </p>
      <p className="text-gray-600 text-sm w-72 text-center mx-auto">
        Drop in task relevant documents
      </p>
      {
        comingSoon &&  <p className="text-white w-40 tracking-wide rounded-xl mt-8 text-center py-3 px-4 bg-blue-500 whitespace-nowrap mx-auto">
        Coming soon...
      </p>
      }
    </div>
  );
};

export default DocumentEmptyScreen;
