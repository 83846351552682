import React, { useState, useEffect, useRef } from "react";
import { Menu } from "@headlessui/react";
import {
  ChevronLeft,
  ChevronRight,
  MoreVertical,
  Calendar,
} from "lucide-react";
import { formatDisplayDate } from "../../utils/date";
import ScheduledTaskComponent from "./ScheduledTask";
import TaskCard from "./TaskCard";
import { getDates } from "../aggrid/AgComponent";

interface Task {
  id: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  status: string;
  priority: string;
  isActive: boolean;
}

interface CalendarDay {
  date: Date;
  isCurrentMonth: boolean;
}

interface CalendarTaskProps {
  tasks: Task[];
  selectedDate: Date;
  setSelectedDate: (id: Date) => void;
  setShowTaskOnCalender: (id: string) => void;
}

const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month + 1, 0).getDate();
};

const getMonthData = (year: number, month: number): CalendarDay[] => {
  const firstDay = new Date(Date.UTC(year, month, 1, 5, 30)).getUTCDay();
  const daysInMonth = getDaysInMonth(year, month);
  const days: CalendarDay[] = [];

  for (let i = 0; i < firstDay; i++) {
    days.push({
      date: new Date(Date.UTC(year, month, -firstDay + i + 1, 5, 30)),
      isCurrentMonth: false,
    });
  }

  for (let i = 1; i <= daysInMonth; i++) {
    days.push({
      date: new Date(Date.UTC(year, month, i, 5, 30)),
      isCurrentMonth: true,
    });
  }

  while (days.length % 7 !== 0) {
    days.push({
      date: new Date(
        Date.UTC(
          year,
          month + 1,
          days.length - daysInMonth - firstDay + 1,
          5,
          30
        )
      ),
      isCurrentMonth: false,
    });
  }

  return days;
};

const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const formatDate = (date: Date): string => {
  return date.toISOString().split("T")[0];
};

const CalendarTask: React.FC<CalendarTaskProps> = ({
  tasks,
  selectedDate,
  setSelectedDate,
  setShowTaskOnCalender,
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  // const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [showUpcoming, setShowUpcoming] = useState<boolean>(false);
  const [showOverDue, setShowOverDue] = useState<boolean>(false);
  const [showAllInRange, setShowAllInRange] = useState<boolean>(false);
  const [monthData, setMonthData] = useState<CalendarDay[]>([]);

  useEffect(() => {
    setMonthData(
      getMonthData(currentDate.getFullYear(), currentDate.getMonth())
    );
  }, [currentDate]);

  const goToPreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const goToNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
  };

  const isTaskRelevant = (task: Task): boolean => {
    const taskStartDate = new Date(task.startDate);
    const taskEndDate = new Date(task.endDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Check if the task is starting or ending today
    if (
      taskStartDate.toDateString() === today.toDateString() ||
      taskEndDate.toDateString() === today.toDateString()
    ) {
      return true;
    }

    if (showUpcoming) {
      const twoDaysFromNow = new Date(
        selectedDate.getTime() + 2 * 24 * 60 * 60 * 1000
      );
      const status =
        (taskStartDate >= selectedDate && taskStartDate <= twoDaysFromNow) ||
        (taskEndDate >= selectedDate && taskEndDate <= twoDaysFromNow);
      if (status) return status;
    }

    if (showAllInRange) {
      const status =
        taskStartDate <= selectedDate && taskEndDate >= selectedDate;
      if (status) return status;
    }
    if (
      showOverDue &&
      task.status.toLowerCase() !== "closed" &&
      getDates(task.endDate) !== null
    ) {
      return true;
    }

    return (
      taskStartDate.toDateString() === selectedDate.toDateString() ||
      taskEndDate.toDateString() === selectedDate.toDateString()
    );
  };

  const relevantTasks = tasks.filter(isTaskRelevant);

  const [showCalender, setShowCalender] = useState(false);
  const contentRef = useRef<any>(null);
  return (
    <div className="bg-slate-50 px-4 pt-4 rounded-xl shadow-box border-gray-300 border ">
      <div
        className={`flex h-8 items-center ${
          !showCalender ? "justify-between" : "justify-between"
        }`}
      >
        {/* {showCalender && ( */}
        <h2 className="text-sm font-semibold text-gray-900">
          {!showCalender
            ? "Scheduled Task"
            : currentDate.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
        </h2>
        {/* )} */}
        <div className="flex items-center space-x-4">
          {showCalender && (
            <button
              type="button"
              onClick={goToPreviousMonth}
              className="p-1.5 text-gray-400 hover:text-gray-500"
            >
              <ChevronLeft className="h-5 w-5" />
            </button>
          )}
          {showCalender && (
            <button
              type="button"
              onClick={goToNextMonth}
              className="p-1.5 text-gray-400 hover:text-gray-500"
            >
              <ChevronRight className="h-5 w-5" />
            </button>
          )}

          {/* <p
            className="text-sm cursor-pointer"
            onClick={() => setShowCalender(!showCalender)}
          >
            {showCalender ? "Hide Calender" : "View Calender"}
          </p> */}
        </div>
      </div>
      {showCalender && (
        <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
          <div>Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div>Sat</div>
        </div>
      )}
      {/* {showCalender && <div className="mt-2 grid grid-cols-7 text-sm"> */}
      <div
        className="overflow-hidden transition-[max-height] duration-300 ease-in-out"
        style={{
          maxHeight: showCalender
            ? `${contentRef.current?.scrollHeight}px`
            : "0px",
        }}
      >
        <div ref={contentRef}>
          <div className="mt-2 grid grid-cols-7 text-sm">
            {monthData.map((day, dayIdx) => (
              <div
                key={day.date.toISOString()}
                className={`py-2 ${
                  dayIdx > 6 ? "border-t border-gray-200" : ""
                }`}
              >
                <button
                  type="button"
                  onClick={() => handleDateClick(day.date)}
                  className={`mx-auto flex h-8 w-8 items-center justify-center rounded-full
                ${isToday(day.date) ? "bg-indigo-600 text-white" : ""}
                ${
                  day.date.toDateString() === selectedDate.toDateString() &&
                  !isToday(day.date)
                    ? "bg-indigo-200"
                    : ""
                }
                ${!day.isCurrentMonth ? "text-gray-400" : "text-gray-900"}
                ${!isToday(day.date) && "hover:bg-gray-200"}
              `}
                >
                  <time dateTime={formatDate(day.date)}>
                    {day.date.getDate()}
                  </time>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <section className="pt-8 px-6 -mx-4 pb-4 rounded-b-xl bg-white">
        <ScheduledTaskComponent
          showCalender={showCalender}
          showUpcoming={showUpcoming}
          setShowUpcoming={setShowUpcoming}
          showAllInRange={showAllInRange}
          setShowAllInRange={setShowAllInRange}
          selectedDate={formatDisplayDate(selectedDate)}
          showOverDue={showOverDue}
          setShowOverDue={setShowOverDue}
        />
        {relevantTasks.length === 0 && (
          <div className="mt-4">
            {/* Placeholder for scheduled tasks */}
            <p>Your scheduled tasks will be displayed here.</p>
          </div>
        )}
        <ol className="mt-4 space-y-4 text-sm leading-6 h-[480px] overflow-auto text-gray-500">
          {relevantTasks.map((task) => {
            const isToday =
              new Date(task.startDate).toDateString() ===
                new Date().toDateString() ||
              new Date(task.endDate).toDateString() ===
                new Date().toDateString();
            return (
              <TaskCard
                task={task}
                setShowTaskOnCalender={setShowTaskOnCalender}
              />
              // <li
              //   key={task.id}
              //   className={`group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100 ${
              //     isToday ? "border-l-4 border-indigo-600" : ""
              //   }`}
              // >
              //   <div className="flex-auto">
              //     <p className="text-gray-900">{task.name}</p>
              //     <p className="mt-0.5">
              //       <time dateTime={task.startDate}>
              //         {new Date(task.startDate).toLocaleDateString()}
              //       </time>{" "}
              //       -{" "}
              //       <time dateTime={task.endDate}>
              //         {new Date(task.endDate).toLocaleDateString()}
              //       </time>
              //     </p>
              //   </div>
              //   <Menu
              //     as="div"
              //     className="relative opacity-0 focus-within:opacity-100 group-hover:opacity-100"
              //   >
              //     <Menu.Button className="-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600">
              //       <MoreVertical className="h-6 w-6" />
              //     </Menu.Button>
              //     <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              //       <Menu.Item>
              //         {({ active }) => (
              //           <a
              //             href="#"
              //             className={`block px-4 py-2 text-sm ${
              //               active
              //                 ? "bg-gray-100 text-gray-900"
              //                 : "text-gray-700"
              //             }`}
              //           >
              //             Edit
              //           </a>
              //         )}
              //       </Menu.Item>
              //       <Menu.Item>
              //         {({ active }) => (
              //           <a
              //             href="#"
              //             className={`block px-4 py-2 text-sm ${
              //               active
              //                 ? "bg-gray-100 text-gray-900"
              //                 : "text-gray-700"
              //             }`}
              //           >
              //             Cancel
              //           </a>
              //         )}
              //       </Menu.Item>
              //     </Menu.Items>
              //   </Menu>
              // </li>
            );
          })}
        </ol>
      </section>
    </div>
  );
};

export default CalendarTask;
