import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { HStack } from "../../shared/utils";
import { sort } from "../../shared/arrays";

function SearchStringAutoCompleteDropDown<T>({
  getSelectedValue,
  onChange,
  className,
  placeholder,
  data,
  width,
  hint,
}: {
  getSelectedValue: (item?: string) => void;
  onChange: (item?: string) => void;
  data: string[];
  placeholder?: string;
  className?: string;
  width?: string;
  hint?: string;
}) {
  const sortedData = data.sort();
  const [searchText, setSearchText] = useState("");
  const [searched, setSearched] = useState<string[]>();

  const [open, setOpen] = useState(false);
  function searchOptions(text: string) {
    const match = sortedData.filter((d) => {
      const regex = new RegExp(`${text}`, "gi");
      return (d as any).match(regex);
    });
    setSearched(match);
  }
  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!searchText) setSearchText("");
      if (!(open && contextRef.current?.contains(e.target))) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [open, searchText]);
  return (
    <div
      ref={contextRef}
      className={`bg-white z-10 relative rounded-md ${width}`}
    >
      <HStack
        className={`items-center w-full rounded-md border border-[#B5B5C3] bg-white h-8 shadow-sm text-slate-dark ${
          className ? `${className} h-12` : className
        } `}
      >
        <input
          type="text"
          className="w-full px-2 text-xs font-medium text-[#464E5F] outline-none border-none bg-inherit py-0 my-0"
          value={searchText}
          placeholder={placeholder ?? "Select"}
          onChange={(e) => {
            if (!e.target.value) setSearched(sortedData);
            setSearchText(e.target.value);
            searchOptions(e.target.value);
            onChange(e.target.value ?? "");
          }}
          onFocus={() => {
            setOpen(true);
            setSearched(sortedData);
          }}
          onBlur={() => getSelectedValue(undefined)}
        />
        {open ? (
          <Icon
            icon="material-symbols:keyboard-arrow-up-rounded"
            width="18"
            className="mr-2 "
            onClick={() => setOpen(!open)}
          />
        ) : (
          <Icon
            icon="material-symbols:keyboard-arrow-down-rounded"
            width="24"
            className="mr-2 "
            onClick={() => setOpen(!open)}
          />
        )}
      </HStack>
      {open && (
        <div
          className={`overflow-auto  border divide-y divide-gray-100 rounded shadow-sm max-h-60 cursor-pointer  ${className}`}
        >
          {searched?.map((option, index) => {
            return (
              <div
                className="flex gap-2 p-2 divide-y rounded "
                key={index}
                onClick={() => {
                  setSearchText(option);
                  getSelectedValue(option);
                  setOpen(!open);
                }}
              >
                {option}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SearchStringAutoCompleteDropDown;
