import {
    CellClickedEvent,
    ColDef,
    GridApi,
    SideBarDef,
  } from "ag-grid-community";
  import { AgGridReact } from "ag-grid-react";
  
  export const agConfigDashboardSideBar: SideBarDef = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        minWidth: 225,
        width: 225,
        maxWidth: 225,
        toolPanelParams: {
          hide: true,
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true,
        },
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
        minWidth: 180,
        maxWidth: 400,
        width: 250,
      },
    ],
  };
  