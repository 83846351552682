import React, { useEffect, useState } from "react";
import { Settings } from "lucide-react";
import { formatDisplayDate } from "../../utils/date";
import { FilterBadge } from "../aggrid/Activity";
import { HStack } from "../../shared/utils";

// const ScheduledTaskComponent = ({ selectedDate }:{ selectedDate:string }) => {

const ScheduledTaskComponent = ({
  showCalender,
  selectedDate,
  showUpcoming,
  setShowUpcoming,
  setShowOverDue,
  showAllInRange,
  setShowAllInRange,
}: {
  selectedDate: string;
  showCalender: boolean;
  showUpcoming: boolean;
  showOverDue: boolean;
  setShowOverDue: (ele: boolean) => void;
  setShowUpcoming: (ele: boolean) => void;
  showAllInRange: boolean;
  setShowAllInRange: (ele: boolean) => void;
}) => {
  const selected = selectedDate.split(" ").slice(0, 2).join(" ");
  const tillToday = `Till ${selected}`;
  const [filter, setFilter] = useState<string[]>([selected]);
  useEffect(() => {
    let arr = [selected];
    if (showUpcoming) {
      arr.push("UPCOMING");
    }
    if (showAllInRange) {
      arr.push(tillToday);
    }
    setFilter(arr);
  }, [selected]);
  return (
    <div className="relative">
      <div className="flex flex-col items-start justify-between gap-2">
        {showCalender && (
          <h2 className="text-base font-semibold leading-6 text-gray-900">
            Scheduled Tasks
          </h2>
        )}
        <HStack className="flex-wrap">
          {[selected, "UPCOMING", tillToday, "OVERDUE"].map((type) => (
            <FilterBadge
              key={type}
              type={type}
              isActive={filter.includes(type)}
              onClick={() => {
                if (type !== selected) {
                  if (filter.includes(type)) {
                    setFilter((prev: any) =>
                      prev.filter((t: any) => t !== type)
                    );
                    if (type === tillToday) {
                      setShowAllInRange(false);
                    }
                    if (type === "OVERDUE") {
                      setShowOverDue(false);
                    }
                    if (type === "UPCOMING") {
                      setShowUpcoming(false);
                    }
                  } else {
                    setFilter((prev: any) => [...prev, type]);
                    if (type === tillToday) {
                      setShowAllInRange(true);
                    }
                    if (type === "OVERDUE") {
                      setShowOverDue(true);
                    }
                    if (type === "UPCOMING") {
                      setShowUpcoming(true);
                    }
                  }
                }
              }}
            />
          ))}{" "}
        </HStack>
      </div>
    </div>
  );
};

export default ScheduledTaskComponent;
