import React from "react";

export const taskstates = [
  {
    name: "Open",
    key: "CREATED",
    color: "bg-blue-500",
    textColor: "text-blue-700",
    borderColor: "border-blue-500",
  },
  {
    name: "In Progress",
    key: "INPROGRESS",
    color: "bg-yellow-500",
    textColor: "text-yellow-700",
    borderColor: "border-yellow-500",
  },
  {
    name: "Completed",
    key: "COMPLETED",
    color: "bg-green-500",
    textColor: "text-green-500",
    borderColor: "border-green-300",
  },
  {
    name: "Cancelled",
    color: "bg-red-500",
    key: "CANCELLED",
    textColor: "text-red-700",
    borderColor: "border-red-500",
  },
  {
    name: "Closed",
    key: "CLOSED",
    color: "bg-green-700",
    textColor: "text-green-800",
    borderColor: "border-green-800",
  },
];

export function IssueStateDots({ counts }: { counts: any }) {
  const activeStates = taskstates.filter(
    (state) => counts[state.name.toLowerCase().replace(" ", "")] > 0
  );
  return (
    <div className="flex items-center space-x-3">
      {activeStates.map((state, index) => (
        <div key={state.name} className="flex flex-row items-center">
          {index > 0 && <span className="mr-3 text-gray-300">•</span>}
          <div className={`w-2 h-2 rounded-full ${state.color}`}></div>
          <div
            className={`ml-2 flex gap-1 flex-row items-center whitespace-nowrap text-sm font-medium ${state.textColor}`}
          >
            <span className="sm:block hidden"> {state.name}:</span>
            <span>{counts[state.name.toLowerCase().replace(" ", "")]}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
