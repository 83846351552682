import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import {
  useGetNotification,
  useUpdateNotificationSetting,
} from "../../queries/userRole";

export default function Setting({
  project,
  isAdmin,
}: {
  project: any;
  isAdmin: boolean;
}) {
  const [due1Day, setDue1Date] = useState(false);
  const [due2Day, setDue2Date] = useState(false);
  const [due3Day, setDue3Date] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const [oneWeekBefore, setOneWeekBefore] = useState(false);
  const [twoWeekBefore, setTwoWeekBefore] = useState(false);
  const { data: notification } = useGetNotification(project.id);
  const { mutate: updateNotification } = useUpdateNotificationSetting();
  useEffect(() => {
    if (notification)
      notification?.forEach((ele) => {
        if (ele.reminderFrequency === "ONE_DAY_BEFORE")
          setDue1Date(ele.isEnabled);
        else if (ele.reminderFrequency === "TWO_DAYS_BEFORE")
          setDue2Date(ele.isEnabled);
        else if (ele.reminderFrequency === "THREE_DAYS_BEFORE")
          setDue3Date(ele.isEnabled);
        else if (ele.reminderFrequency === "ONE_WEEK_BEFORE")
          setOneWeekBefore(ele.isEnabled);
        else if (ele.reminderFrequency === "TWO_WEEK_BEFORE")
          setTwoWeekBefore(ele.isEnabled);
        else if (ele.eventType === "STATUS_CHANGED")
          setSendNotification(ele.isEnabled);
      });
  }, [notification]);
  const updateNotificationValue = ({
    eventType,
    value,
  }: {
    eventType: any;
    value: boolean;
  }) => {
    if (eventType === "STATUS_CHANGED") {
      updateNotification({
        entityType: "TASK",
        eventType: "STATUS_CHANGED",
        isEnabled: value,
        projectId: project.id,
        id:
          notification?.find((ele) => ele.eventType === "STATUS_CHANGED")?.id ??
          "",
      });
    } else if (eventType === "ONE_DAY_BEFORE") {
      updateNotification({
        entityType: "TASK",
        eventType: "REMINDER",
        isEnabled: value,
        projectId: project.id,
        id:
          notification?.find(
            (ele) => ele.reminderFrequency === "ONE_DAY_BEFORE"
          )?.id ?? "",
        reminderFrequency: "ONE_DAY_BEFORE",
      });
    } else if (eventType === "TWO_DAYS_BEFORE") {
      updateNotification({
        entityType: "TASK",
        eventType: "REMINDER",
        isEnabled: value,
        projectId: project.id,
        id:
          notification?.find(
            (ele) => ele.reminderFrequency === "TWO_DAYS_BEFORE"
          )?.id ?? "",
        reminderFrequency: "TWO_DAYS_BEFORE",
      });
    } else if (eventType === "THREE_DAY_BEFORE") {
      updateNotification({
        entityType: "TASK",
        eventType: "REMINDER",
        isEnabled: value,
        projectId: project.id,
        id:
          notification?.find(
            (ele) => ele.reminderFrequency === "THREE_DAYS_BEFORE"
          )?.id ?? "",
        reminderFrequency: "THREE_DAYS_BEFORE",
      });
    } else if (eventType === "ONE_WEEK_BEFORE") {
      updateNotification({
        entityType: "TASK",
        eventType: "REMINDER",
        isEnabled: value,
        projectId: project.id,
        id:
          notification?.find(
            (ele) => ele.reminderFrequency === "ONE_WEEK_BEFORE"
          )?.id ?? "",
        reminderFrequency: "ONE_WEEK_BEFORE",
      });
    } else if (eventType === "TWO_WEEK_BEFORE") {
      updateNotification({
        entityType: "TASK",
        eventType: "REMINDER",
        isEnabled: value,
        projectId: project.id,
        id:
          notification?.find(
            (ele) => ele.reminderFrequency === "TWO_WEEK_BEFORE"
          )?.id ?? "",
        reminderFrequency: "TWO_WEEK_BEFORE",
      });
    }
  };

  return (
    <form>
      <div className="space-y-12 px-8 py-2">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Notifications
          </h2>
          <p className="mt-1 sm:block hidden text-sm leading-6 text-gray-600">
            Never miss updates. Customize all other notifications.
          </p>

          <div className="mt-10 space-y-10">
            <fieldset>
              <legend className="text-sm font-semibold leading-6 text-gray-900">
                Status Change
              </legend>
              <div className="mt-6 space-y-6">
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="comments"
                      name="comments"
                      checked={sendNotification}
                      onChange={() => {
                        setSendNotification(!sendNotification);
                        updateNotificationValue({
                          eventType: "STATUS_CHANGED",
                          value: !sendNotification,
                        });
                      }}
                      type="checkbox"
                      className="h-4 w-4  cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium  cursor-pointer text-gray-900"
                    >
                      Send Email Notification
                    </label>
                    <p className="text-gray-500">
                      Get notified when someones changes status of task.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend className="text-sm font-semibold leading-6 text-gray-900">
                Due Notification
              </legend>
              <div className="mt-6 space-y-6">
                <div className="relative flex gap-x-3 cursor-pointer">
                  <div className="flex h-6 items-center">
                    <input
                      id="due1day"
                      name="due1day"
                      type="checkbox"
                      checked={due1Day}
                      onChange={() => {
                        setDue1Date(!due1Day);
                        updateNotificationValue({
                          eventType: "ONE_DAY_BEFORE",
                          value: !due1Day,
                        });
                      }}
                      className="h-4 w-4  cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="due1day"
                      className="font-medium  cursor-pointer text-gray-900"
                    >
                      1 DAY BEFORE
                    </label>
                  </div>
                </div>
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="due2day"
                      name="due2day"
                      type="checkbox"
                      checked={due2Day}
                      onChange={() => {
                        setDue2Date(!due2Day);
                        updateNotificationValue({
                          eventType: "TWO_DAYS_BEFORE",
                          value: !due2Day,
                        });
                      }}
                      className="h-4 w-4  cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="due2day"
                      className="font-medium  cursor-pointer text-gray-900"
                    >
                      2 DAY BEFORE
                    </label>
                  </div>
                </div>
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="due3Day"
                      name="due3Day"
                      type="checkbox"
                      checked={due3Day}
                      onChange={() => {
                        setDue3Date(!due3Day);
                        updateNotificationValue({
                          eventType: "THREE_DAY_BEFORE",
                          value: !due3Day,
                        });
                      }}
                      className="h-4 w-4 rounded  cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="due3Day"
                      className="font-medium  cursor-pointer text-gray-900"
                    >
                      3 DAY BEFORE
                    </label>
                  </div>
                </div>
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="oneWeekBefore"
                      name="oneWeekBefore"
                      type="checkbox"
                      checked={oneWeekBefore}
                      onChange={() => {
                        setOneWeekBefore(!oneWeekBefore);
                        updateNotificationValue({
                          eventType: "ONE_WEEK_BEFORE",
                          value: !oneWeekBefore,
                        });
                      }}
                      className="h-4 w-4 rounded  cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="oneWeekBefore"
                      className="font-medium  cursor-pointer text-gray-900"
                    >
                      1 WEEK BEFORE
                    </label>
                  </div>
                </div>
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="twoWeekBefore"
                      name="twoWeekBefore"
                      type="checkbox"
                      checked={twoWeekBefore}
                      onChange={() => {
                        setTwoWeekBefore(!twoWeekBefore);
                        updateNotificationValue({
                          eventType: "TWO_WEEK_BEFORE",
                          value: !twoWeekBefore,
                        });
                      }}
                      className="h-4 w-4 rounded  cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="twoWeekBefore"
                      className="font-medium  cursor-pointer text-gray-900"
                    >
                      2 WEEK BEFORE
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </form>
  );
}
