import { QueryFunctionContext } from "react-query";
import api from "../queries/api";

export interface TaskDocument {
  createdAt: string;
  id: string;
  isDeleted: boolean;
  location: string;
  name: string;
  projectId: string;
  tags: string[];
  taskId: string;
  type: string;
  updatedAt: string;
  uploadUserId: string;
  uploadedAt: string;
}
export async function getAllTaskDocuments(
  context: QueryFunctionContext
): Promise<any> {
  const taskId = context.queryKey[1];
  return api
    .get(`project/task/document/all`, { params: { taskId } })
    .then((res) => res.data);
}
export async function getAllProjectDocuments(
  context: QueryFunctionContext
): Promise<any> {
  const projectId = context.queryKey[1];
  return api
    .get(`project/document/all`, { params: { projectId } })
    .then((res) => res.data);
}

export async function generatePresignedUrlForTask(document: any): Promise<any> {
  return api
    .post(`project/task/document/generatePresignedUrl`, document)
    .then((res) => res.data);
}

export async function generatePresignedUrlForProject(
  document: any
): Promise<any> {
  return api
    .post(`project/document/generatePresignedUrl`, document)
    .then((res) => res.data);
}

export async function downloadProjectFiles(fileDetails: any): Promise<any> {
  return api.post(`project/document/download`, fileDetails).then((res) => res.data);
}
export async function downloadTaskFiles(fileDetails: any): Promise<any> {
  return api.post(`project/task/document/download`, fileDetails).then((res) => res.data);
}

export async function createTaskDocument(document: any): Promise<any> {
  return api.post(`project/task/document`, document).then((res) => res.data);
}

export async function uploadImportTemplate(formData: FormData): Promise<any> {
  return api.post(`project/import`, formData).then((res) => res.data);
}

export async function createProjectDocument(document: any): Promise<any> {
  return api.post(`project/document`, document).then((res) => res.data);
}

export async function deleteTaskDocument({
  documentId,
  taskId,
}: {
  documentId: any;
  taskId: any;
}): Promise<any> {
  return api
    .delete(`project/task/document?documentId=${documentId}&taskId=${taskId}`)
    .then((res) => res.data);
}

export async function deleteProjectDocument({
  documentId,
  projectId,
}: {
  documentId: any;
  projectId: any;
}): Promise<any> {
  return api
    .delete(`project/document?documentId=${documentId}&projectId=${projectId}`)
    .then((res) => res.data);
}
