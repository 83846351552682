import { Icon } from "@iconify/react";
import { useEffect, useState, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  ArrowsRightLeftIcon,
  ChevronDownIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { useLocation, useMatch, useNavigate } from "react-router";
import * as React from "react";
import { toast } from "react-toastify";
import { useAuthStore } from "../../store/useAuthStore";
import { Settings, Settings2 } from "lucide-react";
import { HStack, VStack } from "../../shared/utils";
import Avatar from "./Avatar";
import LogoutModal from "../../modal/LogoutModal";
import { useGetUserProfile } from "../../queries/userRole";

export function FlyoutProfile() {
  let tasks: any[] = [];
  const [dialogLogout, setDialogLogout] = useState(false);
  const [show, setShown] = useState(false);
  const [showDevice, setShowDevice] = useState(false);

  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");

  const contextRef = React.useRef<HTMLDivElement>(null);
  const contextRef2 = React.useRef<HTMLDivElement>(null);
  const { data } = useGetUserProfile();
  const [name, setName] = useState("");
  useEffect(() => {
    if (data) {
      setName(data?.name ?? "");
    }
  }, [data]);
  const { role, email } = useAuthStore();
  const routes = [
    {
      name: "Settings",
      onClick: () => {
        navigate("/settings");
        setShown(false);
        setShowDevice(false);
      },
      icon: Settings2,
    },
    {
      name: "Log Out",
      onClick: () => {
        setDialogLogout(true);
        setShown(false);
        setShowDevice(false);
      },
      icon: ArrowLeftOnRectangleIcon,
    },
  ];

  const profileMatch = useMatch(`/${"/user/profile".split("/")[1]}/*`);
  const { pathname } = useLocation();

  return (
    <div>
      {false ? (
        <div className="items-center px-6 py-2">
          <p className="text-sm animate-pulse font-medium">Switching...</p>
        </div>
      ) : (
        <Popover className="relative">
          {dialogLogout && (
            <LogoutModal
              isOpen={dialogLogout}
              onClose={() => {
                setDialogLogout(false);
              }}
            />
          )}
          <Popover className="relative">
            <div>
              <Popover.Button
                onClick={() => setShown(!show)}
                onMouseEnter={() => {
                  setShown(true);
                }}
                onMouseLeave={() => {
                  setShown(false);
                  setShowDevice(false);
                }}
                className={`-ml-1.5 flex gap-2 cursor-pointer items-center p-1  ${
                  "/profile" !== pathname
                    ? "bg-white text-secondary"
                    : "bg-secondary text-white"
                } shadow-box h-11 border px-2 rounded-xl`}

                // className="inline-flex items-center gap-x-1 text-sm py-2 bg-white font-semibold leading-6 text-gray-900 "
              >
                <Avatar isDark={"/profile" !== pathname} name={name || ""} />
                <p className="text-sm text-gray-700">{name}</p>
                <span className="hidden lg:flex lg:items-center">
                  <ChevronDownIcon
                    className="ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Popover.Button>
            </div>
            <Transition
              show={show}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                onMouseEnter={() => {
                  setShown(true);
                }}
                onMouseLeave={() => {
                  setShown(false);
                  setShowDevice(false);
                }}
                ref={contextRef}
                className="absolute z-10 mt-5 flex w-screen max-w-max  right-0 "
              >
                <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg">
                  <div className="divide-x divide-gray-900/5 bg-gray-50">
                    <div className="group relative flex gap-x-2 rounded-lg p-4 hover:bg-gray-50">
                      <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <Avatar name={name || ""} />
                      </div>
                      <VStack className="w-full">
                        <HStack className="justify-between items-center w-full">
                          <a className="font-semibold text-gray-900 flex-1">
                            {name}

                            <span className="absolute inset-0" />
                          </a>

                          <a className="ml-1 text-sm text-gray-500 text-ellipsis overflow-clip">
                            {role}
                          </a>
                        </HStack>

                        <p className="text-gray-600 whitespace-nowrap">
                          {email}
                        </p>
                        <p className="text-sm text-gray-500 text-ellipsis overflow-clip"></p>
                      </VStack>
                    </div>
                  </div>

                  <div className="p-2  cursor-pointer">
                    {routes.map((item) => (
                      <div
                        key={item.name}
                        onClick={() =>
                          item.name === "Switch Device"
                            ? setShowDevice(!showDevice)
                            : item.onClick()
                        }
                        className="group relative flex gap-x-4 rounded-lg p-2 hover:bg-gray-50 items-center"
                      >
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className={`h-6 w-6 text-gray-600 group-hover:text-primary ${
                              profileMatch && item.name === "Profile"
                                ? "text-primary"
                                : ""
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                        <div>
                          <a
                            className={`font-semibold text-gray-900 group-hover:text-primary ${
                              profileMatch && item.name === "Profile"
                                ? "text-primary"
                                : ""
                            }`}
                          >
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </Popover>
      )}
    </div>
  );
}
