// import React, { useState } from "react";
// import { Users, X } from "lucide-react";
// import { Select } from "../../shared/Select";

// const AssigneeComponent = () => {
//   const [assignees, setAssignees] = useState<
//   { role: string, id: string }[]
//   >([]);
//   let options = [
//     {
//       id: 123,
//       name: "Nikhil",
//     },
//     {
//       id: 124,
//       name: "MMudakavi",
//     },
//     {
//       id: 125,
//       name: "Genie",
//     },
//   ];
//   const [editMode, setEditMode] = useState(false);
//   const [newAssignee, setNewAssignee] = useState("");

//   const handleAddAssignee = () => {
//     if (newAssignee.trim() !== "") {
//       setAssignees([
//         ...assignees,
//         { role: "ASSIGNEE", id: newAssignee },
//       ]);
//       setNewAssignee("");
//     }
//   };

//   const handleRemoveAssignee = (id: any) => {
//     setAssignees(assignees.filter((assignee) => assignee.id !== id));
//   };

//   return (
//     <div className="flex flex-col items-start p-4 border rounded-md">
//       <div className="flex items-center mb-4">
//         <Users className="w-5 h-5 mr-2 text-blue-500" />
//         <span className="font-medium">Assigned to</span>
//       </div>

//       {!editMode ? (
//         <div
//           onClick={() => setEditMode(true)}
//           className="flex items-center text-gray-700 hover:bg-gray-100 cursor-pointer p-2 rounded"
//         >
//           {assignees.length > 0 ? (
//             <span className="text-gray-800">
//               {assignees[0].name}
//               {assignees.length > 1 && (
//                 <span className="text-blue-500">{` & ${
//                   assignees.length - 1
//                 } More`}</span>
//               )}
//             </span>
//           ) : (
//             <span className="text-gray-800">Not Assigned</span>
//           )}
//         </div>
//       ) : (
//         <div className="w-full">
//           <div className="flex flex-wrap gap-2 mb-2">
//             {assignees.map((assignee) => (
//               <span
//                 key={assignee.id}
//                 className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium flex items-center"
//               >
//                 {assignee.name}
//                 <X
//                   className="w-4 h-4 ml-2 cursor-pointer"
//                   onClick={() => handleRemoveAssignee(assignee.id)}
//                 />
//               </span>
//             ))}
//           </div>
//           <div className="flex items-center mb-2">
//             <Select
//               options={options}
//               valueGetter={(ele) => ele.id}
//               textGetter={(ele) => ele.name}
//               value={newAssignee}
//               onChange={(e) => setNewAssignee(e.target.value)}
//               placeholder="Add assignee"
//               className="flex-grow p-2 border rounded-md mr-2"
//             />
//             <button
//               onClick={handleAddAssignee}
//               className="bg-blue-500 text-white px-4 py-2 rounded-md"
//             >
//               Add
//             </button>
//           </div>
//           <div className="flex justify-end mt-2">
//             <button
//               onClick={() => setEditMode(false)}
//               className="text-gray-800 border border-gray-700 bg-white py-1 px-2 text-sm rounded-md"
//             >
//               Done
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AssigneeComponent;

import React from 'react'

const Assignee = () => {
  return (
    <div>
      
    </div>
  )
}

export default Assignee
